/*
 * jQuery UI CSS Framework 1.11.4
 * http://jqueryui.com
 *
 */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
    display: none;
}
.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
    content: "";
    display: table;
    border-collapse: collapse;
}
.ui-helper-clearfix:after {
    clear: both;
}
.ui-helper-clearfix {
    min-height: 0; /* support: IE7 */
}
.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter:Alpha(Opacity=0); /* support: IE8 */
}

.ui-front {
    z-index: 100;
}


/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
    cursor: default !important;
}


/* Icons
----------------------------------*/

/* states and images */
/*.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}*/


/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ui-accordion .ui-accordion-header {
    display: block;
    cursor: pointer;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 0; /* support: IE7 */
    font-size: 100%;
}
/*.ui-accordion .ui-accordion-icons {
    padding-left: 2.2em;
}
.ui-accordion .ui-accordion-icons .ui-accordion-icons {
    padding-left: 2.2em;
}
.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    left: .5em;
    top: 50%;
    margin-top: -8px;
}*/
.ui-accordion .ui-accordion-content {
    padding: 0;
    overflow: auto;
}



/* Uniform styles
==================================*/

/* General settings */
div.selector, div.checker span, div.radio span{
  /*background-image: url("../images/sprite.png");
  background-repeat: no-repeat;*/
  border:1px solid $input-border;
  background: $input-bg;
  -webkit-font-smoothing: antialiased; 
}

div.selector, div.checker, div.radio {
  display: -moz-inline-box;
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: middle;
  /* Keeping this as :focus to remove browser styles */ 
}

div.selector:focus, div.checker:focus, div.radio:focus {
    outline: 0; 
}
div.selector, div.selector *, div.radio, div.radio *, div.checker, div.checker *{
  margin: 0;
  padding: 0; 
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"],
div.checker input, div.radio input{
    margin:0;
    position:absolute;
    left:-2px;
    top:-2px;
}

.checkbox label{
    padding-left:30px;
    .checker{
        position:absolute;
        left:0;
    }
}

.radio label{
    padding-left:30px;
    .checker{
        position:absolute;
        left:0;
    }
}



/* Remove default webkit and possible mozilla .search styles.
 * Keeping this as :active to remove browser styles */
div.checker input,
div.radio input {
  -moz-appearance: none;
  -webkit-appearance: none; 
}



/* Select */
div.selector {
  position: relative;
  overflow: hidden; 
  width: 100%;
  max-width:100%;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include border-radius($input-border-radius);
  &:before{
    color: $grey-color;
    content: $icon-chevron-down;
    position: absolute;
    right:11px;
    top:11px;
    font-size: 18px;
    display:inline-block;
    font-family: $icons-font-family;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

div.selector span {
    color: $input-color;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    background-position: right 0;
    height: 40px;
    line-height: 38px;
    padding-right: 40px;
    padding-left: 10px;
    cursor: pointer;
    width: 100%;
    max-width:100%;
    display: block;
    text-align:left;
}
/*div.selector.fixedWidth {
    width: 190px; 
}
div.selector.fixedWidth span {
    width: 155px; 
}*/
div.selector select {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    position: absolute;
    height: 38px;
    top: 0;
    left: 0;
    width: 100%;
}

div.selector.active {
    //background-position: 0 -156px; 
    border-color: $brand-color;
}
/*div.selector.active span {
    background-position: right -26px; 
}*/
div.selector.focus {
    //background-position: 0 -182px; 
    $color-rgba: rgba(red($input-border-focus), green($input-border-focus), blue($input-border-focus), .6);
    border-color: $input-border-focus;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba);
}
/*div.selector.hover span, div.selector.focus span {
    background-position: right -52px; 
}*/
/*div.selector.hover.active, div.selector.focus.active {
    background-position: 0 -208px;
}*/
/*div.selector.hover.active span, div.selector.focus.active span {
    background-position: right -78px; 
}*/
div.selector.disabled, div.selector.disabled.active {
    &:before{
        color: #d2d2d2;
    }
}
div.selector.disabled span, div.selector.disabled.active span {
    color: #d2d2d2;
}




/* Checkbox */
div.checker {
  position: relative; 
}
div.checker, div.checker span, div.checker input {
    width: 20px;
    height: 20px; 
    cursor:pointer;
}
div.checker span {
    position:relative;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center;
}
div.checker span.checked {
    &:before{
        content: '';
        position:absolute;
        width: 10px;
        height:10px;
        top:10px;
        left:10px;
        margin-top:-7px;
        margin-left:-7px;
        background: $brand-color;
    }
}
div.checker input {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1; 
    text-align:center;
}
div.checker.active span {
    border-color: $brand-color;
}
div.checker.hover span, div.checker.focus span {
    border-color: $brand-color;
}
div.checker.hover.active span, div.checker.focus.active span {
    background: #fff;
}


div.checker.disabled, 
div.checker.disabled.active {
    span{
        border-color: #dfdfdf!important;
        background: #f5f5f5!important;
    }
    &:before{
        background-color: #dfdfdf!important;
    }
}
div.checker.disabled span.checked, 
div.checker.disabled.active span.checked {
    &:before{
        background-color: #d2d2d2!important;
    }
}




/* Radio */


div.radio {
  position: relative; 
}
div.radio, div.radio span, div.radio input {
    width: 22px;
    height: 22px;
}
div.radio span {
    position:relative;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center;
    @include border-radius(50%);
}
div.radio span.checked {
    &:before{
        content: '';
        position:absolute;
        width: 12px;
        height:12px;
        top:10px;
        left:10px;
        margin-top:-7px;
        margin-left:-7px;
        background: $brand-color;
        @include border-radius(50%);
    }
}
div.radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center; 
}

div.radio.active span {
    border-color: $brand-color;
}
div.radio.hover span, div.radio:hover span, div.radio.focus span {
    border-color: $brand-color;
}
div.radio.hover.active span, div.radio.focus.active span {
    background: #fff;
}

div.radio.disabled, 
div.radio.disabled.active {
    span{
        border-color: #dfdfdf!important;
        background: #f5f5f5!important;
    }
    &:before{
        background-color: #dfdfdf!important;
    }
}
div.radio.disabled span.checked, 
div.checker.disabled.active span.checked {
    &:before{
        background-color: #d2d2d2!important;
    }
}


/* General settings */
/*div.checker span, 
div.radio span{
  background-image: url("../img/uniform_sprite.png");
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
}
div.checker, 
div.radio{
    position:relative;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: middle;
}
div.checker:focus, 
div.radio:focus{
    outline: 0; 
}
div.radio, 
div.radio *, 
div.checker, 
div.checker * {
    margin: 0;
    padding: 0; 
}

div.checker input,
div.radio input{
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1; 
}

div.checker input{
    -moz-appearance: none;
    -webkit-appearance: none; 
}*/


/* Checkbox */

/*
div.checker, 
div.checker span, 
div.checker input {
    width: 19px;
    height: 19px; 
}
div.checker span {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center;
    background-position: 0 0; 
}
div.checker span.checked {
      background-position: -76px 0; 
}
div.checker.active span {
    background-position: -19px 0; 
}
div.checker.active span.checked {
    background-position: -95px 0; 
}
div.checker.hover span, 
div.checker.focus span {
    background-position: -38px 0; 
}
div.checker.hover span.checked, 
div.checker.focus span.checked {
    background-position: -114px 0; 
}
div.checker.hover.active span, 
div.checker.focus.active span {
    background-position: -57px 0; 
}
div.checker.hover.active span.checked, 
div.checker.focus.active span.checked {
    background-position: -133px 0; 
}
div.checker.disabled, div.checker.disabled.active {
    background-position: -152px 0; 
}
div.checker.disabled span.checked, 
div.checker.disabled.active span.checked {
    background-position: -171px 0; 
}*/

/* Radio */
/*div.radio, div.radio span, div.radio input {
    width: 18px;
    height: 18px; 
}
div.radio span {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center;
    background-position: 0 -19px; 
}
div.radio span.checked {
    background-position: -72px -19px; 
}
div.radio input {
    text-align: center; 
}
div.radio.active span {
    background-position: -18px -19px; 
}
div.radio.active span.checked {
    background-position: -90px -19px; 
}
div.radio.hover span, 
div.radio.focus span {
    background-position: -36px -19px; 
}
div.radio.hover span.checked, 
div.radio.focus span.checked {
    background-position: -108px -19px;
}
div.radio.hover.active span, 
div.radio.focus.active span {
    background-position: -54px -19px; 
}
div.radio.hover.active span.checked, 
div.radio.focus.active span.checked {
     background-position: -126px -19px; 
}
div.radio.disabled span, 
div.radio.disabled.active span {
    background-position: -144px -19px; 
}
div.radio.disabled span.checked, 
div.radio.disabled.active span.checked {
    background-position: -162px -19px; 
}*/

/*-----------------------------------------------------------------------------------

Header

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/

.top-bar {
	position: relative;
	background-color: $brand-color-light;
	height: $topbar-height;
	line-height: $topbar-line-height;
	font-size: 12px;
	font-family: $secondary-font;
	color: $topbar-color;
	text-align:right;
	display:block;
	padding:0 10px;

	.widget_search {
		input { max-height: 30px; max-width: 180px; }
		button { background: black; max-height: 30px; border: black; }
		display: block;
	}

}


/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/


#header {
	position: relative;
	background-color: #FFF;
	border-bottom: 1px solid #F5F5F5;

	.top-bar-wg {
	   background: transparent;
	   border: 0;
	   margin-top: 5px;
	   opacity: 1;
	   float: right;
	}

	.top-bar{
		background:transparent;
		border:0;
		float: right;
        margin-top: 40px;
		opacity: 1;
		-webkit-transition: all .3s ease;
		-o-transition: all .3s ease;
		transition: all .3s ease;
		max-width: 75%;
		line-height: 1.4em;
	}

	.container {
		position: relative;
	}
	&.full-header {
		border-bottom-color: #EEE;
	}
	#header-wrap{
		background: #fff;
	}

}

body.stretched #header.full-header .container {
	width: 100%;
	padding: 0 30px;
}


/* ----------------------------------------------------------------
	Logo
-----------------------------------------------------------------*/


.logo {
	position: relative;
	float: left;
	font-size: 36px;
	line-height: 100%;
	a {
		display: block;
		color: #000;
		float:left;
	}

	img {
		display: block;
		max-width: 100%;
	}

	a.standard-logo {
		display: block;
	}

	a.retina-logo {
		display: none;
	}
}


.header_style_3{
	.sticky-logo{
		float:  right;
	}
}

@include xs () {

.header_style_3{
	.sticky-logo{
		/* float:  right;
		   margin-right: -58px;
			 */
		float: none;
		position: absolute;
		top: 0px; right: 10px;
		z-index: 99;
	}
}
}


/* ----------------------------------------------------------------
	Primary Menu & Tiny Menu
-----------------------------------------------------------------*/

.header-menu {
	float: right;
	background-color: #fff;
	position:absolute;
	right:0;
	top:0;
}

.header_style_3 {
	.header-menu{
		float:left;
		right: auto;
		left:0;
	}
}

.header-menu {
	ul {
		list-style: none;
		margin: 0;
	}

	> ul { float: left; }

	ul {
		li {
			position: relative;
			float: left;

			a {
				display: block;
				padding: 0 15px;
				color: $menu_color;
				background-color: $menu_bg;
				font-weight: $primary-menu-font-weight;
				font-size: $primary-menu-font-size;
				letter-spacing: $primary-menu-font-spacing;
				text-transform: $primary-menu-font-tt;
				font-family: $primary-menu-font;
				border-bottom:$header-menu-border solid transparent;
				-webkit-transition: all .3s ease;
				-o-transition: all .3s ease;
				transition: all .3s ease;
				text-align:center;
				&:hover,
				&:focus{
					background-color: $menu_hover_bg;
					border-bottom: $header-menu-border solid $primary-menu-font-color!important;
				}
			}

			&.active > a,
			&.open > a{
				color: $menu_hover_color;
				background-color: $menu_hover_bg;
                     		border-bottom:$header-menu-border solid $brand-color!important;
			}

			ul.dropdown-menu{
				border: 0;
				border-top: $submenu_top_border_width $submenu_top_border_style $submenu_top_border_color;
				@include border-radius(0);
				@include clearfix();
				padding:0px;
			}

			ul {
				background-color: $submenu-bg;
				.col2-title { text-transform: uppercase; color: $submenu_hover_color; }
				.col2-menu { border-left: 2px #e4e4e4 solid; }
			}

			ul li{
				width:100%;
				border-bottom: $submenu_border_width $submenu_border_style $submenu_border_color;
				background: $submenu_bg;

				&:last-child{
					border-bottom:0;
				}
				a{
					text-align:left;
					padding: 12px 20px 12px 20px;
					height: auto;
					border: 0;
					background-color: $submenu_bg;
					font-size: 14px;
					color: $submenu_color;
					&:hover,
					&:focus{
						background: $submenu_hover_bg;
						color: $submenu_hover_color;
					}
				}
				&.active > a{
					background: $submenu_hover_bg;
					color: $submenu_hover_color;
				}
			}

		}

	}
}


#tiny-menu{
	ul li a{
		font-size:32px;
		padding: 0 25px;
	}
	.side-panel-trigger{
		border-bottom:0;
		&:hover,
		&:focus{
			border-bottom:none!important;
		}
	}
}



/* ----------------------------------------------------------------
	Sticky Header
-----------------------------------------------------------------*/

#header,
#header #header-wrap,
.header-menu,
.header-menu ul,
.header-menu ul > li,
.header-menu ul > li > a,
#side-header,
#side-header a { height: $sticky-header-height + 20; }

#header{
	height: $sticky-header-height + 22;
}
.header-menu ul > li > a,
#side-header a { line-height: $sticky-header-height + 20 + 2; }

.logo img{
	height: $logo-height;
	margin-top: ($sticky-header-height + 20 - $logo-height) / 2;
	margin-bottom: ($sticky-header-height + 20 - $logo-height) / 2;
}

.header-menu ul > li ul {
	height: auto;
	li{
		height: auto;
		a{
			height: auto;
			line-height: 1.5;
		}
	}
}


#header,
#header-wrap,
.logo,
.header-menu{
	-webkit-transition:  height .3s ease;
	-o-transition: height .3s ease;
	transition: height .3s ease;
}

.header-menu  ul,
#side-header,
#side-header a {
	-webkit-transition:  all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

.logo img{
	height: $logo-height;
	margin-top: ($sticky-header-height + 20 - $logo-height) / 2;
	margin-bottom: ($sticky-header-height + 20 - $logo-height) / 2;
	-webkit-transition:  all .3s ease;
	-o-transition: all .3s ease;
	transition: all .3s ease;
}

#header-wrap { position: relative; }


@media (min-width: $screen-md-min) {


	#side-header,
	#side-header a { height: $header-height; }
	#side-header a { line-height: $header-height + 2; }

	#header.header_style_1,
	#header.header_style_2{
		height: $header-height;
		#header-wrap,
		.header-menu,
		.header-menu ul,
		.header-menu ul > li,
		.header-menu ul > li > a{
			height: $header-height;

		}
		.header-menu ul > li > a{
			line-height: $header-height;
		}
	}


	#header.header_style_3,
	#header.header_style_4{
		height:  $header-height + $menu-after-height;
		#header-wrap,
		.header-menu,
		.header-menu ul,
		.header-menu ul > li,
		.header-menu ul > li > a{
			height: $menu-after-height;
		}
		.header-menu ul > li > a{
			line-height: $menu-after-height;
		}
	}

	#header.header_style_1,
	#header.header_style_2,
	#header.header_style_3,
	#header.header_style_4{
		.header-menu ul > li ul{
			height: auto;
			li{
				height: auto;
				a{
					height: auto;
					line-height: 1.5;
				}
			}
		}
	}

	.header-menu{
		right:15px;
	}

	.logo img{
		height: $logo-height;
		margin-top: ($header-height - $logo-height) / 2;
		margin-bottom: ($header-height - $logo-height) / 2;

	}

	#header.header_style_3{
			.header-menu{
				right: auto;
				left:15px;
			}

	}

	.sticky-logo{
		display: none;
	}

}


@media (min-width: $screen-lg-min) {
	#primary-menu {
		ul {
			li {
				a {
					padding-left:25px;
					padding-right:25px;
				}
				ul li a{
					padding-left: 21px;
					padding-right:35px;
				}
			}
		}
	}

}


body.sticky-header #header #header-wrap {
	z-index: 199;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #FFF;
	box-shadow: 0 0 10px rgba(0,0,0,0.1);
	-webkit-backface-visibility: hidden;
}

body.sticky-header #header .top-bar{
	opacity: 0;
}

body.sticky-header #header,
body.sticky-header #header #header-wrap,
body.sticky-header #header .header-menu,
body.sticky-header #header .header-menu ul,
body.sticky-header #header .header-menu ul > li,
body.sticky-header #header .header-menu ul > li > a,
body.sticky-header #side-header,
body.sticky-header #side-header a { height: $sticky-header-height; }
body.sticky-header #header .header-menu ul > li > a,
body.sticky-header #side-header a { line-height: $sticky-header-height + 2; }
body.sticky-header #header #tiny-menu .phone-trigger{ border-bottom: none; }

body.sticky-header #header .header-menu ul > li ul {
	height: auto;
	li{
		height: auto;
		a{
			height: auto;
			line-height: 1.5;
		}
	}
}

body.sticky-header #header.header_style_1,
body.sticky-header #header.header_style_2{
	.logo img{
		height: $sticky-header-height - 6;
		margin-top: 3px;
		margin-bottom: 3px;
	}
}

body.sticky-header{
	.sticky-logo{
		display: block;
		img{
			height: $sticky-header-height - 6;
			margin-top: 3px;
			margin-bottom: 3px;
		}
	}
}


@media (min-width: $screen-md-min) {
	body.sticky-header #header.header_style_3,
	body.sticky-header #header.header_style_4{
		height:  $header-height + $sticky-header-height !important;
	}

}


@include md () {

	.header_style_2 {
		.logo img{
			max-height: $logo-sticky-height - 8;
			margin-top: 4px;
			margin-bottom: 3px;
		}
	}

}


@include sm () {

	.header_style_2 {
		.logo img{
			max-height: $logo-sticky-height - 8;
			margin-top: 4px;
			margin-bottom: 3px;
		}
	}

}

@include xs () {

	.header_style_2 {
		.logo img{
			max-height: $logo-sticky-height - 8;
			margin-top: 4px;
			margin-bottom: 3px;
		}
	}

}

/* ----------------------------------------------------------------
	Side Push Panel
-----------------------------------------------------------------*/

body.side-push-panel { overflow-x: hidden; }

#side-panel {
	position: fixed;
	top: 0;
	right: -300px;
	z-index: 399;
	width: 300px;
	height: 100%;
	background-color: #fff;
	overflow: hidden;
	-webkit-backface-visibility: hidden;

}

#side-panel #side-header{
	position: relative;
	background-color: #fff;
	border-bottom:1px solid #fafafa;
	overflow:hidden;
	.side-panel-trigger {
		float:right;
		display: inline-block;
		font-size:26px;
		padding: 0 30px;
		width:100%;
		-webkit-transition: all .3s ease;
		-o-transition: all .3s ease;
		transition: all .3s ease;
	}
}

#side-panel .side-menu{
	ul{
		li{
			a{
				display: block;
				padding: 18px 35px 18px 30px;
				clear: both;
				line-height: 1.5;
				color: #707070;
				white-space: nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
				font-weight: $primary-menu-font-weight;
				font-size: $primary-menu-font-size;
				letter-spacing: $primary-menu-font-spacing;
				text-transform: $primary-menu-font-tt;
				font-family: $primary-menu-font;
				background-color: #fff;
				border-bottom:1px solid #fafafa;
				-webkit-transition: all .3s ease;
				-o-transition: all .3s ease;
				transition: all .3s ease;
				&:hover,
				&:focus{
					color: $brand-color;
				}
			}

			&.active a{
				color: $brand-color;
			}

			ul li a{
				padding-left: 45px;
			}

			&.active ul li{
				a{
					color: #707070;
					&:hover,
					&:focus{
						color: $brand-color;
					}
				}
				&.active a{
					color: $brand-color;
				}
			}

			/*.collapse-menu {
			    width: 100%;
			    padding: 0;
			    margin: 0;
			    padding-left:35px;
			    font-size: 12px;
			    text-align: left;
			    background-color: #f2f2f2;
			    border: 0;
			    @include border-radius(0);
			    @include box-shadow(none);
			    ul{
			    	list-style:none;

			    }
			    > li{
			    	display:inline-block;
			    	padding: 10px 5px 5px 10px;
			    	a{
			    		color: red;
			    	}
			    }

			    .active > a{
			    	text-decoration: none;
					color: $dropdown-link-hover-color;
					background-color: $dropdown-bg;
					&:hover,
					&:focus{
						background-color: $dropdown-bg;
					}
			    }

			}*/

		}

	}

}




body.side-panel-left #side-panel {
	left: -300px;
	right: auto;
}

body.side-push-panel.stretched #wrapper,
body.side-push-panel.stretched.sticky-header #header .container { right: 0; }

body.side-panel-left.side-push-panel.stretched #wrapper,
body.side-panel-left.side-push-panel.stretched.sticky-header #header .container {
	left: 0;
	right: auto;
}

#side-panel .side-panel-wrap {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 330px;
	height: 100%;
	overflow: auto;
	//padding: 50px 70px 50px 40px;
}

body.device-touch #side-panel .side-panel-wrap { overflow-y: scroll; }

/*
#side-panel .widget {
	margin-top: 40px;
	padding-top: 40px;
	width: 220px;
	border-top: 1px solid #E5E5E5;
}

#side-panel .widget:first-child {
	padding-top: 0;
	border-top: 0;
	margin: 0;
}
*/

body.side-panel-open #side-panel { right: 0; }

body.side-panel-left.side-panel-open #side-panel {
	left: 0;
	right: auto;
}

body.side-push-panel.side-panel-open.stretched #wrapper,
body.side-push-panel.side-panel-open.stretched.sticky-header #header .container { right: 300px; }

body.side-panel-left.side-push-panel.side-panel-open.stretched #wrapper,
body.side-panel-left.side-push-panel.side-panel-open.stretched.sticky-header #header .container {
	left: 300px;
	right: auto;
}

/*#side-panel-trigger {
	display: block;
	cursor: pointer;
	z-index: 11;
	//margin-right: 5px;
}*/


//#side-panel-trigger-close a { display: none; }

#side-panel,
body.side-push-panel.stretched #wrapper,
body.side-push-panel.stretched #header .container {
	-webkit-transition: right .3s ease;
	-o-transition: right .3s ease;
	transition: right .3s ease;
}

body.side-panel-left #side-panel,
body.side-panel-left.side-push-panel.stretched #wrapper,
body.side-panel-left.side-push-panel.stretched #header .container {
	-webkit-transition: left .3s ease;
	-o-transition: left .3s ease;
	transition: left .3s ease;
}

.body-overlay {
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: -1;
	pointer-events: none;
	-webkit-backface-visibility: hidden;
	-webkit-transition: opacity .3s ease;
	-o-transition: opacity .3s ease;
	transition: opacity .3s ease;
}

body.side-panel-open .body-overlay {
	opacity: 1;
	z-index: 298;
	pointer-events: auto;
}

/*-----------------------------------------------------------------------------------

Sliders

-----------------------------------------------------------------------------------*/

.slider-container {
	margin: -1px auto;
	position:relative;
	overflow:hidden;
	width:100%;
	-webkit-backface-visibility:hidden;
	z-index:1;
	border-bottom: 4px solid $brand-primary;
}

.slider-parallax-outer{
	position:relative;
	width:100%;
}

.slider-parallax,
.slider-parallax .slider-parallax-outer {
	visibility: visible;
	-webkit-transform: translate(0,0);
	-ms-transform: translate(0,0);
	-o-transform: translate(0,0);
	transform: translate(0,0);
	-webkit-backface-visibility: hidden;
}


.slider,
.tiny-slider {
	position:relative;
	width:100%;
}

.bx-wrapper,
.slider-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.bx-wrapper img,
.slider-wrapper img {
  max-width: 100%;
  display: block;
}

.slider,
.bxslider {
  margin: 0;
  padding: 0;
}

ul.slider,
ul.bxslider {
  list-style: none;
}

.bx-viewport {
  /*fix other elements on the page moving*/
	-webkit-transform: translatez(0);
	-ms-transform: translatez(0);
	-o-transform: translatez(0);
	transform: translatez(0);
}

/* THEME */
.bx-wrapper,
.slider-wrapper {
  background: #fff;
}

.slider-wrapper .bx-pager,
.slider-wrapper .bx-controls-auto,
.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: 10px;
  width: 100%;
  z-index:100;
}

/* LOADER */
.slider-wrapper .bx-loading,
.bx-wrapper .bx-loading {
  /*min-height: 50px;
  background: url('images/bx_loader.gif') center center no-repeat #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;*/
}


/* PAGER */
.slider-wrapper .bx-pager,
.bx-wrapper .bx-pager {
  text-align: center;
}

.slider-wrapper .bx-pager .bx-pager-item,
.slider-wrapper .bx-controls-auto .bx-controls-auto-item,
.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item{
	display:inline-block;
	*zoom: 1;
    *display: inline;
}
.slider-wrapper .bx-pager a,
.bx-wrapper .bx-pager a {
	background: transparent;
    text-indent: -9999px;
    display: block;
    width: 12px;
    height: 12px;
    border:2px solid #fff;
    margin: 0 5px;
    outline: 0;
    @include border-radius(50%);
}

.slider-wrapper .bx-pager a:hover,
.slider-wrapper .bx-pager a.active,
.slider-wrapper .bx-pager a:focus,
.bx-wrapper .bx-pager a:hover,
.bx-wrapper .bx-pager a.active,
.bx-wrapper .bx-pager a:focus {
  background-color: $brand-color;
  border-color: $brand-color;
}

/*
.slider-wrapper .bx-pager a.active,
.bx-wrapper .bx-pager a.active{
	&:before {
		content: $ionicon-var-record;
	}
}*/

/* DIRECTION CONTROLS (NEXT / PREV) */

.slider-wrapper .bx-controls-direction a,
.bx-wrapper .bx-controls-direction a {
  position: absolute;
  cursor: pointer;
  top: 50%;
  margin-top: -26px;
  outline: 0;
  width: 62px;
  height: 62px;
  color: #fff;
  color: rgba(255,255,255,0.3);
  background-color: transparent;
  border: 0;
  -webkit-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
  z-index: 100;
}

.slider-wrapper .bx-prev,
.bx-wrapper .bx-prev {
  left: -15px;
}

.slider-wrapper .bx-next,
.bx-wrapper .bx-next {
  right: -15px;
}

.slider-wrapper .bx-prev:hover,
.slider-wrapper .bx-prev:focus,
.slider-wrapper .bx-next:hover,
.slider-wrapper .bx-next:focus,
.bx-wrapper .bx-prev:hover,
.bx-wrapper .bx-prev:focus,
.bx-wrapper .bx-next:hover,
.bx-wrapper .bx-next:focus {
   color: #fff;
}


.slider-wrapper .bx-controls-direction a.disabled,
.bx-wrapper .bx-controls-direction a.disabled {
  color: #fff;
  display:none;
  cursor: default;
}

.slider-wrapper .bx-controls-direction a .icon,
.bx-wrapper .bx-controls-direction a .icon{
	line-height: 62px;
	height: 62px;
	width: 36px;
	// width: 100px;
	color:inherit;
	font-size: 34px;
	text-align: center;
	margin-left: 14px;
}

.slider-wrapper .bx-next .icon,
.bx-wrapper .bx-next .icon { margin-left: 10px; }



/* AUTO CONTROLS (START / STOP) */

/*.bx-wrapper .bx-controls-auto {
  text-align: center;
}
.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -11px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active,
.bx-wrapper .bx-controls-auto .bx-start:focus {
  background-position: -86px 0;
}
.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url('images/controls.png') -86px -44px no-repeat;
  margin: 0 3px;
}
.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active,
.bx-wrapper .bx-controls-auto .bx-stop:focus {
  background-position: -86px -33px;
}
*/


/* SLIDER CAPTION */

.slider-caption {
	color: #fff;
	font-size: 16px;
	font-weight: normal;
	-webkit-transition: top .3s ease;
	-o-transition: top .3s ease;
	transition: top .3s ease;

	&.shadow-yes{
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
	}

	.slide-title {
		font-size: 24px;
		font-weight: 300;
		letter-spacing: 0.5pt;
		//text-transform: uppercase;
		line-height: 1.2;
		//margin-bottom: 20px;
		color: #fff;
		margin: 10px 0;
	}

	p{
		margin: 5px 0;
		font-size: 14px;
		&.action-p{
			margin-top: 20px;
		}
	}

	.btn{
		text-shadow:none;
		padding-top: 15px;
		padding-bottom:15px;
	}

	&.slider-caption-right {
		text-align:right;
	}

	&.slider-caption-center {
		text-align: center;
		margin: 0 auto;
	}
}


/* SLIDE (VERTICAL CENTER) */

.slider .slide,
.featured-slide {
  	position:absolute;
  	background-position: 50% 50%;
  	background-repeat: no-repeat;
  	background-size: cover;
  	overflow: hidden;
  	margin: 0;
  	.container {
			position: relative;
			height: 100%;
			padding-left: 35px;
			padding-right: 35px;
			.slider-caption-wrapper{
				position:relative;
				width:100%;
				height:100%;
			}
			.slider-caption{
				position:absolute;
				width:96%;
				left:2%;
				bottom:50px;

				&.vcentered{
					position:relative;
					bottom: auto;
				}

				&.slider-vertical-top{
					bottom:auto;
					top: 40px;
				}

				.slider-caption-inside{
					@include clearfix();
					padding:0;
				}
				
				.slider-caption-dark{
					padding: 15px;
					background: rgba(0,0,0,0.5);
				}

				.slider-caption-light{
					padding: 15px;
					background: rgba(255,255,255,0.5);
					color: #333;
					text-shadow: none;
					.slide-title {
						color: #333;
					}
				}
				
				.slider-text-light{
					color: #fff;
					.slide-title{
						color: #fff;
					}
				}
				
				.slider-text-dark{
					color: #333;
					.slide-title{
						color: #333;
					}
				}

			}
		}

		[data-caption-animate] {
			-webkit-backface-visibility: hidden;
		}

}


.featured-slide{
	width:100%;
}



/* FOOTER TINY SLIDERS */

.tiny-slider-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.tiny-slider-wrapper .bx-controls{
	position:relative;
	//margin-top:20px;
}

.tiny-slider-wrapper .bx-controls-direction a {
  position: relative;
  width: 30px;
  height: 30px;
  font-size: 14px;
  &:hover,
  &:focus{
  	 color: $brand-color;
  }
}

.tiny-slider-wrapper .bx-controls-direction a.disabled {
  opacity: .3;
  cursor: default;
  &:hover,
  &:focus{
  	 opacity: .3;
  	 color: $brand-color;
  }
}

.tiny-slider-wrapper .bx-next{
	margin-left:10px;
}


/* RESPONSIVE SLIDER SIZES */

.slider-container,
.slider-container .slider-parallax-outer,
.slider-container .slide,
.slider-container .featured-slide{
	height: 400px;
}

.slider-big,
.slider-big .slider-parallax-outer,
.slider-big .slide{
	height: 400px;
}

.slider-tiny,
.slider-tiny .slider-parallax-outer,
.slider-tiny .slide{
	height: $slider_tiny_height;
}

@media (min-width: $screen-xs-min) {


	.slider-wrapper .bx-prev,
	.bx-wrapper .bx-prev {
	  left: -5px;
	}

	.slider-wrapper .bx-next,
	.bx-wrapper .bx-next {
	  right: -5px;
	}


	.slider-caption {
		font-size:16px;
		.slide-title {
			font-size: 28px;
			margin: 15px 0;
		}
	}


}


@media (min-width: $screen-sm-min) {

	.slider-normal,
	.slider-normal .slider-parallax-outer,
	.slider-normal .slide,
	.slider-normal .featured-slide{
		height:400px;
	}

	.slider-big,
	.slider-big .slider-parallax-outer,
	.slider-big .slide,
	.slider-big .featured-slide{
		height:500px;
	}

	.slider-tiny,
	.slider-tiny .slider-parallax-outer,
	.slider-tiny .slide{
		height: $slider_tiny_height;
	}

	.slider .slide,
	.featured-slide{
		  	.container {
				padding-left: 30px;
				padding-right: 30px;
				
				.slider-caption{
					width: 80%;
					left: 5%;
					
					&.vcentered{
						position:relative;
					}
					
					&.slider-caption-right {
						text-align:right;
						right: 5%;
						left: auto;
						&.vcentered{
							right: auto;
							left: 15%;
						}
					}

					&.slider-caption-center {
						text-align:center;
						left: 10%;
					}
				}
			}
	}


	.slider-caption {
		font-size:18px;
		.slide-title {
			font-size: 36px;
			margin: 10px 0;
		}

		p{
			margin: 10px 0;
			font-size: 16px;
			&.action-p{
				margin-top: 30px;
			}
		}

		.btn{
			padding-left: 50px;
			padding-right: 50px;
		}
	}

}

@media (min-width: $screen-md-min) {

	.slider-big{
		.slider-wrapper .bx-pager,
		.slider-wrapper .bx-controls-auto,
		.bx-wrapper .bx-pager,
		.bx-wrapper .bx-controls-auto{
			bottom:20px;
		}
	}


	.slider .slide,
	.featured-slide{
		  .container {
				padding-left: 15px;
				padding-right: 15px;
				.slider-caption{
					.slider-caption-inside{
						padding:15px;
					}
					.slider-caption-light,
					.slider-caption-dark{
						padding: 30px;
					}
				}
			}
	}
	.slider .slide, 
	.featured-slide{
				.container {
					.slider-caption{
						width: 70%;
						left: 0;
						
						&.vcentered{
							position:relative;
						}
						
						&.slider-caption-right {
							text-align:right;
							right: 0;
							left: auto;
							&.vcentered{
								right: auto;
								left: 30%;
							}
						}
	
						&.slider-caption-center {
							text-align:center;
							left: 15%;
						}
						
						&.slider-with-bg{
							width: 50%;
							&.slider-caption-right {
								text-align:left;
								&.vcentered{
									right: auto;
									left: 50%;
								}
							}
							&.slider-caption-center {
								text-align:center;
								left: 25%;
							}
						}
					}
				}
		}


}

@media (min-width: $screen-lg-min) {

	.slider-big{
		.slider-wrapper .bx-pager,
		.slider-wrapper .bx-controls-auto,
		.bx-wrapper .bx-pager,
		.bx-wrapper .bx-controls-auto{
			bottom:30px;
		}
	}

	.slider-wrapper .bx-prev,
	.bx-wrapper .bx-prev {
	  left: 0;
	}

	.slider-wrapper .bx-next,
	.bx-wrapper .bx-next {
	  right: 0;
	}

	.slider-normal,
	.slider-normal .slider-parallax-outer,
	.slider-normal .slide,
	.slider-normal .featured-slide{
		height:420px;
	}

	.slider-big,
	.slider-big .slider-parallax-outer,
	.slider-big .slide,
	.slider-big .featured-slide{
		height:600px;
	}

	.slider-tiny,
	.slider-tiny .slider-parallax-outer,
	.slider-tiny .slide{
		height: $slider_tiny_height;
	}

	.slider-caption {
		font-size:20px;
		.slide-title {
			font-size: 44px;
			margin: 15px 0;
			margin-left: -3px;
		}

		p{
			margin: 15px 0;
			font-size: 18px;
			&.action-p{
				margin-top: 40px;
			}
		}

		.btn{
			padding-left: 60px;
			padding-right: 60px;
			//font-size:24px;
		}
	}

}


/* Slim Scroll */

.slimScrollBar{
    opacity: 0.5!important;
    border-radius: 0!important;
}


/* Clients Ticker Carousel */

.section-clients {

	margin: 0 auto;
	padding: ($section-padding - 20) 0 ($section-padding - 20) 0;

	ul {
		list-style-type: none;
	}

	.client{
		display: block;
		text-align: center;
	}

	.clients-ticker{
		.slide{
			@include opacity(0.6);
			@include transition(all .35s);
			&:hover, &:focus{
				@include opacity(1);
			}
		}
		.client{
		  max-width:  200px;
			max-height: auto;
		}
	}

	.bx-wrapper{
		background: transparent;
	}

	.clients-normal{
		.client{
			margin-bottom: 20px;
			img{
				max-height: 100%;
			}
		}
	}

	.slide{
	   margin: 0px; padding: 0px;
	}

}

@media (min-width: $screen-sm-min) {
	.section-clients .clients-normal .client{
		max-width: 160px;
		height: auto;
	}
}

@media (min-width: $screen-md-min) {
	.section-clients .clients-normal .client{
		max-width: 190px;
		height: auto;
	}
}

@media (min-width: $screen-lg-min) {
	.section-clients .clients-normal .client{
		max-width: 200px;
		height: auto;
	}
}

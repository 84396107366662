/**
 @victor shortcuts
 */

/* basic colors */
.black  { color:black;   }
.black2 { color:#424242; }
.white  { color:white;   }

.gray1  { color: #efefef; } .bg-gray1 { background: #efefef; }
.gray2  { color: #9f9d9e; } .bg-gray2 { background: #9f9d9e; }


/** font details */
.bold   { font-weight:bold ; }
.underline { text-decoration: underline; border-bottom: 1px #666 solid; }


/** font sizes */

@for $i from 10 through 80 {
  .fs#{$i}   { font-size: #{$i}px; }
  .fsb#{$i}  { font-size: #{$i}px; font-weight: bold;   }
  .fsi#{$i}  { font-size: #{$i}px; font-style:  italic; }
  .fsbi#{$i} { font-size: #{$i}px; font-weight: bold; font-style:  italic; }
}

@include sm () {
  @for $i from 16 through 80 {
    .fs#{$i}-sm   { font-size: #{$i}px - 5; }
    .fsb#{$i}-sm  { font-size: #{$i}px - 5; }
    .fsi#{$i}-sm  { font-size: #{$i}px - 5; }
    .fsbi#{$i}-sm { font-size: #{$i}px - 5; }
  }
}

@include xs () {
  @for $i from 16 through 80 {
    .fs#{$i}-xs   { font-size: #{$i}px - 20; }
    .fsb#{$i}-xs  { font-size: #{$i}px - 20; }
    .fsi#{$i}-xs  { font-size: #{$i}px - 20; }
    .fsbi#{$i}-xs { font-size: #{$i}px - 20; }
  }
}

/** widths and heights */

@for $i from 5 through 15 {
  $v: $i * 5;
  .w#{$v}r { width: percentage($i); }
  .w#{$v}a  { width: #{$i}px; }
  .h#{$v}a  { width: #{$i}px; }
  .wh#{$v}a { height: #{$i}px; width: #{$i}px; }
}

@for $i from 2 through 40 {
  .lh#{$i} { line-height: #{$i}px; }
}

/** paddings and margins */

@for $i from 0 through 100 {
  .p#{$i}    { padding: #{$i}px; }
  .pt#{$i}   { padding-top:    #{$i}px; }
  .pb#{$i}   { padding-bottom: #{$i}px; }
  .pbt#{$i}  { padding-bottom: #{$i}px; }
  .pr#{$i}   { padding-right:  #{$i}px; }
  .pl#{$i}   { padding-left:   #{$i}px; }
  .m#{$i}    { margin: #{$i}px;  }
  .mt#{$i}   { margin-top:     #{$i}px; }
  .mbt#{$i}  { margin-bottom:  #{$i}px; }
  .mb#{$i}   { margin-bottom:  #{$i}px; }
  .mr#{$i}   { margin-right:   #{$i}px; }
  .ml#{$i}   { margin-left:    #{$i}px; }
}

@include xs () {

  @for $i from 0 through 100 {
    .p#{$i}-xs    { padding: #{$i}px; }
    .pt#{$i}-xs   { padding-top:    #{$i}px; }
    .pb#{$i}-xs   { padding-bottom: #{$i}px; }
    .pbt#{$i}-xs  { padding-bottom: #{$i}px; }
    .pr#{$i}-xs   { padding-right:  #{$i}px; }
    .pl#{$i}-xs   { padding-left:   #{$i}px; }
    .m#{$i}-xs    { margin: #{$i}px;  }
    .mt#{$i}-xs   { margin-top:     #{$i}px; }
    .mbt#{$i}-xs  { margin-bottom:  #{$i}px; }
    .mb#{$i}-xs   { margin-bottom:  #{$i}px; }
    .mr#{$i}-xs   { margin-right:   #{$i}px; }
    .ml#{$i}-xs   { margin-left:    #{$i}px; }
  }

}

@include sm () {

  @for $i from 0 through 100 {
    .p#{$i}-sm    { padding: #{$i}px; }
    .pt#{$i}-sm   { padding-top:    #{$i}px; }
    .pb#{$i}-sm   { padding-bottom: #{$i}px; }
    .pbt#{$i}-sm  { padding-bottom: #{$i}px; }
    .pr#{$i}-sm   { padding-right:  #{$i}px; }
    .pl#{$i}-sm   { padding-left:   #{$i}px; }
    .m#{$i}-sm    { margin: #{$i}px;  }
    .mt#{$i}-sm   { margin-top:     #{$i}px; }
    .mbt#{$i}-sm  { margin-bottom:  #{$i}px; }
    .mb#{$i}-sm   { margin-bottom:  #{$i}px; }
    .mr#{$i}-sm   { margin-right:   #{$i}px; }
    .ml#{$i}-sm   { margin-left:    #{$i}px; }
  }

}


@include md () {

  @for $i from 0 through 100 {
    .p#{$i}-md    { padding: #{$i}px; }
    .pt#{$i}-md   { padding-top:    #{$i}px; }
    .pb#{$i}-md   { padding-bottom: #{$i}px; }
    .pbt#{$i}-md  { padding-bottom: #{$i}px; }
    .pr#{$i}-md   { padding-right:  #{$i}px; }
    .pl#{$i}-md   { padding-left:   #{$i}px; }
    .m#{$i}-md    { margin: #{$i}px;  }
    .mt#{$i}-md   { margin-top:     #{$i}px; }
    .mbt#{$i}-md  { margin-bottom:  #{$i}px; }
    .mb#{$i}-md   { margin-bottom:  #{$i}px; }
    .mr#{$i}-md   { margin-right:   #{$i}px; }
    .ml#{$i}-md   { margin-left:    #{$i}px; }
  }

}


@include lg () {

  @for $i from 0 through 100 {
    .p#{$i}-lg    { padding: #{$i}px; }
    .pt#{$i}-lg   { padding-top:    #{$i}px; }
    .pb#{$i}-lg   { padding-bottom: #{$i}px; }
    .pbt#{$i}-lg  { padding-bottom: #{$i}px; }
    .pr#{$i}-lg   { padding-right:  #{$i}px; }
    .pl#{$i}-lg   { padding-left:   #{$i}px; }
    .m#{$i}-lg    { margin: #{$i}px;  }
    .mt#{$i}-lg   { margin-top:     #{$i}px; }
    .mbt#{$i}-lg  { margin-bottom:  #{$i}px; }
    .mb#{$i}-lg   { margin-bottom:  #{$i}px; }
    .mr#{$i}-lg   { margin-right:   #{$i}px; }
    .ml#{$i}-lg   { margin-left:    #{$i}px; }
  }

}


/** absolute widths/heighs */

.wh100a { width: 100px; height: 100px; }
.wh120a { width: 120px; height: 120px; }
.wh150a { width: 130px; height: 120px; }

.brl1 { border-left: 1px #efefef solid; } // border left

/** box shadows */

.sh10              { box-shadow: 10px 10px 15px 3px #888; }
.sh5               { box-shadow: 5px 5px 15px 3px #888; }
.sh2               { box-shadow: 2px 2px  5px 3px #888; }

/** display types */

.iblock { display: inline-block; }

/** float entities */

.fleft  { float: left; }
.fright { float: right; }


.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;

  iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }

}

/* Forms */

form{
	margin:0;
}

.sidebar .search-form{
	font-size: $font-size-base;
	line-height: $line-height-base;
	text-transform: none;
	font-weight: normal;
}


/* Form elements */

.form-control {
  color: $input-color;
  background-color: $input-bg;
  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include form-control-focus;
  @include placeholder;
	&[disabled],
	&[readonly],
	fieldset[disabled] & {
		background-color: $input-bg-disabled;
		opacity: 1;
	}
}
	


/* Search Form */


.search-field{
	@include box-shadow(none);
	//font-size:16px;
}

.btn-search{
	padding: 0 10px;
	height: $input-height-base;
	@include border-radius(0 $input-border-radius $input-border-radius 0);
	.icon{
	   font-size: 22px;
       line-height: 28px;
       vertical-align: middle;
	}
}


/* Teseo Contact Form section */

.section-contact{
	background-color: $contact-bg;
	
	form{
		margin-top: 30px;
		margin-bottom: 30px;
	}
	
	.help-block{
	    font-size: 12px;
	    max-height: 12px; /* 20160804 - victor */
	}
	
	.btn{
		margin-top: 5px;
	}

}



/* Contact Form & Comments form */


/* ----------------------------------------------------------------
	Comment Form
-----------------------------------------------------------------*/

#respond,
#respond form {
	margin-bottom: 0;
}

.commentlist {
	li {
		#respond {
			margin: 30px 0 0;
		}
		li {
			#respond {
				margin-left: 30px;
			}
		}
	}
}


.teseo-form,
.comment-form {

	fieldset{
		margin-bottom:40px;
		&.end-fieldset{
			margin-bottom:0;
		}
	}

	legend{
		border:0;
		margin:0;
	}

	p {
		position:relative;
		margin: 20px 0 0 0;
		&:first-child {
			margin-top: 0;
		}
	}

	p.form-submit{
		margin-bottom: 30px;
	}

	span.required{
		pointer-events:none;
		font-size:18px;
		position:absolute;
		right:15px;
		top:8px;
		color: $secondary-color;
	}

	textarea {
		resize: vertical;
	}

	.field-valid{
		span.required{
			color: $brand-success;
		}
	}

	.field-invalid{
		input, textarea{
			border-color: $brand-danger;
		}
		span.required{
			color: $brand-danger;
		}
	}

	.wpcf7-not-valid-tip,
	span.invalid{
		position:relative;
		top:-2px;
		display:block;
		background-color: $brand-danger;
		color: #fff;
		padding:5px 15px;
		font-size:12px;
		&:before{
	    	content: '';
	    	border-right: 4px solid transparent;
		    border-left: 4px solid transparent;
		    border-bottom: 4px solid $brand-danger;
		    top: 0;
		    left: 30px;
		    margin-right: -4px;
		    margin-top: -4px;
		    position: absolute;
	    }
	}

	#accept-this-error{
		margin-top:10px;
	}

	p.form-terms{
		font-size: 14px;
		.wpcf7-form-control-wrap{
			margin-right:5px;
		}
	}

}

.contact-form{
	.form-radio-group{
		margin: 20px 0 40px;
	}
	.wpcf7-list-item{
		margin-left:0;
		margin-bottom:20px;
		display:block;
		.radio{
			margin-right:7px;
			margin-top: -3px;
		}
	}
	p.form-submit{
		margin-bottom:0;
	}

	div.wpcf7-response-output {
	    margin: 10px 0 30px;
	    padding: 0.5em 1em;
	}

	div.wpcf7-mail-sent-ng {
	    border: 2px solid $brand-danger;
	    color: $brand-danger;
	}

}

.widget .teseo-form{
	.form-control{
		height:32px;
	}

	input[type="text"],
	input[type="email"],
	input[type="url"],
	input[type="tel"],
	input[type="date"],
	textarea {
		font-size:16px;
		padding:10px 20px 10px 20px;
	}

	span.required{
		top:16px;
	}

	.btn{
		padding: 10px;
	}

	p.form-submit{
		margin-bottom: 0;
	}

	fieldset{
		margin-bottom:0;
	}

}

@media (min-width: $screen-sm-min){
	.section-contact{
		.help-block{
		    margin-top: 0;
		}
	}
}

@media (min-width: $screen-md-min) {
	
	.section-contact{
		form{
			margin-top:0;
		}
	}
	.contact-form{

		p.form-submit{
			&:after{
				top:22px;
			}
		}

	}

}
/*-----------------------------------------------------------------------------------

Footer

-----------------------------------------------------------------------------------*/


#footer {
	position: relative;
	background-color: $footer_bg;
	color: $footer_color;;
	font-size: $footer_font_size;
	line-height: 24px;

	> .container,
	.footer-menus,
	.footer-full,
	.footer-copyright {
		position: relative;
	}
	h3, .widget-title, a{
		color: $footer_color;
	}
	a:hover, a:focus{
		color: lighten($footer_color_focus, 10%);
	}
}

.device-lg,
.device-md {
	&.sticky-footer {
		#top-bar { z-index: 299; }
		#header { z-index: 199; }
		#slider,
		#page-submenu,
		#page-title,
		#content,
		#slider,
		#page-submenu,
		#page-title,
		#content { z-index: 2; }
		#footer {
			position: fixed;
			top: auto;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}
	}
}


#footer{

  .footer-widget {
    border-left: 1px #efefef solid;
	};
	.footer-widget:first-child { border: 0px; };

	.widget{
		margin: 0 auto 15px;


		h3, .h3,
		.widget-title{
			position:relative;
			text-transform:uppercase;
			font-size: $footer_title_size;
			margin: 0 0 10px;
			line-height: 24px;
			font-weight: 600;
		}

		.menu-item{
			padding-left: 0;
			margin: 0 0 5px;
			&:before{
				content: '';
			}
		}
	}

}


.footer-menus{
	padding-top: 60px;
	padding-bottom:20px;
	a{
		padding:0;
	}
}

.footer-full {
	border-top: 1px solid $brand-color-light;
	padding: 30px 0 0;
}

.footer-copyright {
	border-top: 1px solid $brand-color-light;
	@include clearfix();
	padding: 30px 0;
	font-size: 12px;
	.copy{
		display:block;
		text-align:center;
	}
	.foot-links{
		 display:block;
		 text-align: center;
		 margin: 10px 0 0;
		 li{
			 margin-left: 3px;
		 }
	 }
}


ul.social-links{
	padding:0;
	list-style-type: none;
	margin: 10px 0 0 0;
	text-align:center;
	li{
		margin:0 1px 0;
		a{
				position:relative;
				width:32px;
				height:32px;
				display:block;
				text-align:center;
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: $brand-color-light;
					@include border-radius(50%);
					top: -5px;
					left: -5px;
					padding:5px;
					-webkit-box-sizing: content-box;
    			-moz-box-sizing: content-box;
   				box-sizing: content-box;
					-webkit-transition: -webkit-transform 0.3s, background-color 0.3s;
					-moz-transition: -moz-transform 0.3s, background-color 0.3s;
					transition: transform 0.3s, background-color 0.3s;
					-webkit-transform: scale(.8);
					-moz-transform: scale(.8);
					-ms-transform: scale(.8);
					transform: scale(.8);
					-webkit-backface-visibility: hidden;
				}
				.icon{
					color:$brand-color;
					position:relative;
					font-size: 22px;
					line-height: 32px;
					z-index:1;
					-webkit-transition: color 0.3s;
					-moz-transition: color 0.3s;
					transition: color 0.3s;
				}
				&:focus,
				&:hover{
					.icon{
						color: #fff;
					}
					&:before{
						background-color: $brand-color-dark;
						-webkit-transform: scale(1);
						-moz-transform: scale(1);
						-ms-transform: scale(1);
						transform: scale(1);
					}
				}
				&.si-linkedin{
					.icon{
						line-height: 30px;
					}
				}
				&.si-twitter:hover, &.si-twitter:focus{
					&:before{
						background-color: $twitter-color;
					}
				}
				&.si-facebook:hover, &.si-facebook:focus{
					&:before{
						background-color: $facebook-color;
					}
				}
				&.si-linkedin:hover, &.si-linkedin:focus{
					&:before{
						background-color: $linkedin-color;
					}
				}
				&.si-youtube:hover, &.si-youtube:focus{
					&:before{
						background-color: $youtube-color;
					}
				}
				&.si-googleplus:hover, &.si-googleplus:focus{
					&:before{
						background-color: $googleplus-color;
					}
				}
				&.si-pinterest:hover, &.si-pinterest:focus{
					&:before{
						background-color: $pinterest-color;
					}
				}
				&.si-vimeo:hover, &.si-vimeo:focus{
					&:before{
						background-color: $vimeo-color;
					}
				}
				&.si-instagram:hover, &.si-instagram:focus{
					&:before{
						background-color: $instagram-color;
					}
				}
				&.si-rss:hover, &.si-rss:focus{
					&:before{
						background-color: $rss-color;
					}
				}
		}
	}
}



@media (min-width: $screen-sm-min){

	.footer-copyright{
		.copy{
			display:inline-block;
			text-align: left;
		}
		.foot-links{
			float:right;
			display:inline-block;
			margin:0;
		}
	}

	ul.social-links{
		float:right;
		margin: -7px -5px 0 0;
	}

}


@media (min-width: $screen-md-min) {
	.footer-copyright {
		.foot-links{
			li{
				margin-left: 15px;
			}
		}
	}
}


#goToTop{
	position:fixed;
	bottom: 77px;
	right: 30px;
	width: 50px;
	height: 50px;
	text-align:center;
	font-size: 32px;
	background: $brand-color-light;
	color: $brand-color;
	@include transition(all .35s ease);
	@include border-radius(50%);
	cursor: pointer;
	z-index: 2;
	.icon{
		line-height: 50px;
	}
	&:hover,
	&:focus{
		background: $brand-color;
		color: #fff;
	}

	&.absolute{
		position:absolute;
		bottom: auto;
		top: -25px;
		left: auto;
		right: $grid-gutter-width / 2;
	}
}

.device-lg,
.device-md {
	&.sticky-footer {
		#footer{
			padding-top: 20px;
		}
		#goToTop.absolute{
			top: 0;
		}
	}
}

#pre-footer {
	/* border-bottom: 10px white solid; */
}

.section-pre-footer {

	.section-image {
		text-align: center;
	}

 .social-links {
    text-align: center;
    position: relative;
    float: none;
    ul {
      text-align: center;
      li {
        text-align: center;
        a { text-align: center; }
        display: inline-block;
      }
    }
 }

}

@include md () {
	.section-pre-footer {
	 .section-image { padding-bottom: 20px; }
	 .section-mid { padding-bottom: 20px; }
  }
}

@include sm () {
	.section-pre-footer {
	 .section-image { padding-bottom: 20px; }
	 .section-mid { padding-bottom: 20px; }
  }
}

@include xs () {
	.section-pre-footer {
	 .section-image { padding-bottom: 20px; }
	 .section-mid { padding-bottom: 20px; }
  }
}

/* ----------------------------------------------------------------
Comments
-----------------------------------------------------------------*/

.section-comments{
	background: inherit !important;
	text-align: left;
}

#comments {
	position: relative;
}

.comment-reply-title{
	margin-bottom: 10px;
}

.comment-list {
	list-style: none;
	padding: 0;
	margin: 0 0 60px;
	border-bottom: 1px solid $brand-color-light;

	li{
		position:relative;
		padding:15px 0;
		margin: 15px 0;
		border-bottom: 1px solid $brand-color-light;

		&:first-child{
			padding-top:0;
		}
		&:last-child{
			border:0;
			padding-bottom:0;
			margin-bottom: 0;
		}
	}


	ol.children{
		list-style: none;
		padding: 15px 0 0;
		border-top: 1px solid $brand-color-light;
		li{
			margin-left: 0;
		}
	}

	.comment-body{
		@include clearfix();
	}

	.comment-meta{
		position:relative;
		padding-left: 90px;
		min-height:70px;
	}

	.comment-author {
		.fn{
			padding-top: 5px;
			font-size: 1.4em;
			text-transform: uppercase;
			font-weight: normal;
			display:block;
		}
		.says{
			display:none;
		}
		img{
			border:1px solid $brand-color-light;
			position:absolute;
			top:0;
			left:0;
			@include border-radius(50%);
		}
	}

	.comment-metadata{
		color: $grey-color;
	}
	
	.comment-metadata a{
		color: inherit;
	}

	.comment-content{
		padding-top:15px;
		padding-bottom: 15px;
		margin-left: 90px;
	}

}

.comment-reply-link {
	display: inline-block;
	position: absolute;
	top: 10px;
	left: auto;
	right: 0;
	color: $grey-color;
	font-size: 0.875em;
	line-height: 1;
	&:hover,
	&:focus {
		color: darken($grey-color, 7%);
	}
}

.comment-awaiting-moderation{
	margin-left: -90px;
    margin-top: 20px;
    margin-bottom: -10px;
    color: $secondary-color;
}



@media (min-width: $screen-sm-min){
	.comment-list {
		li{
			ol.children{
				li{
					margin-left: 30px;
				}
			}
		}
	}
}


/*-----------------------------------------------------------------------------------

Global Styles

-----------------------------------------------------------------------------------*/

.clear {
	clear: both;
	display: block;
	font-size: 0px;
	height: 0px;
	line-height: 0;
	width: 100%;
	overflow:hidden;
}


img { max-width: 100%; }

iframe { border: none !important; }


#wrapper {
	position: relative;
	float: none;
	margin:0 auto;
}

@media (min-width: $screen-lg-min){
	#wrapper {
		width: 1220px;
		@include box-shadow(0 0 10px rgba(0,0,0,0.1));
	}
}


/* ----------------------------------------------------------------
	Stretched Layout
-----------------------------------------------------------------*/

.stretched {
	#wrapper {
		width: 100%;
		margin: 0;
		box-shadow: none;
	}
}


/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/


#content {
	position: relative;
	overflow: hidden;
	
	.content-wrap {
		position: relative;
		padding: 0;
	}

	.container {
		position: relative;
	}
}


.hentry {
	position: relative;
	font-size: $font-size-base;
	text-transform: $font-tt-base;
	font-weight: $font-weight-base;
	letter-spacing: $font-spacing-base;
}


.row-eq-height{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -moz-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow:visible;
}

.item-eq-height, .eq-height-item {
    min-height: 100%;
}






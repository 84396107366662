/*-----------------------------------------------------------------------------------

Social share block

-----------------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------------

Social share block

-----------------------------------------------------------------------------------*/

.section-social-share{
	padding:30px 0;
	h5{
		margin:0 0 10px;
		color: lighten($grey-color, 10%);
	}
	background: #fff;
	border-top: 1px solid #f2f2f2;

	.social-share-list{
		margin:0 -5px 0 -5px;
		text-align:center;
		li{

			.share-icon{
				margin: 0 0 0 2px;
				line-height:1;
				height: 30px;
    		display: inline-block;
    		vertical-align:middle;
    		cursor:pointer;
				&.icon,
				.icon{
					font-size:22px;
					color: lighten($grey-color, 10%);
					line-height:28px;
				}

				&.icon-social-linkedin{
					top:-1px;
				}
				
				&.icon-social-googleplus{
					top: 2px;
				}

				.icon-mail{
					top: 1px;
				}

				/*.counter{
					position:relative;
					font-size: 12px;
					font-family: $secondary-font;
					background: #fff;
					color: $link-color;
					padding: 5px 7px;
					margin-left:7px;
					top:-7px;
					min-width:25px;
					text-align:center;
					display:inline-block;
					line-height:1;
					@include border-radius(2px);
					&:before{
						content: '';
					    border-top: 4px solid transparent;
					    border-bottom: 4px solid transparent;
					    border-right: 4px solid #fff;
					    top: 50%;
					    left: -4px;
					    margin-right: -4px;
					    margin-top: -4px;
					    position: absolute;
					}
				}*/

				&:hover,
				&:focus{
					&.icon-social-twitter{
						color: $twitter-color;
					}
					&.icon-social-facebook{
						color: $facebook-color;
					}
					&.icon-social-linkedin{
						color: $linkedin-color;
					}
					&.icon-social-googleplus{
						color: $googleplus-color;
					}
					.icon-mail{
						color: $brand-color;
					}
				}
			}
			
		}
	}
}


@media (min-width: $screen-xs-min) {
	.section-social-share{
		h5{
			margin:0;
			float:left;
		}
		.social-share-list{
			margin: 0 auto;
			.share-icon{
				margin: 0 0 0 7px;
				&.icon,
				.icon{
					font-size:27px;
					line-height:28px;
				}

				&.icon-social-twitter-thin{
					font-size:29px;
				}

				.icon-newsletter{
					font-size:34px;
				}
			}
		}
	}
}


@media (min-width: $screen-md-min) {
	.section-social-share{
		.social-share-list{
			float:right;
			margin:0;
		}
	}
}

/* ----------------------------------------------------------------
	Retina CSS
-----------------------------------------------------------------*/

@media only screen and (-webkit-min-device-pixel-ratio: 2),
       only screen and (   min--moz-device-pixel-ratio: 2),
       only screen and (     -o-min-device-pixel-ratio: 2/1),
       only screen and (        min-device-pixel-ratio: 2),
       only screen and (                min-resolution: 192dpi),
       only screen and (                min-resolution: 2dppx) {
	.logo a.standard-logo,
	.logo-foot a.standard-logo { display: none; }
	.logo a.retina-logo,
	.logo-foot a.retina-logo { display: inline-block; }

	.preloader {
		background-image: url('#{$img-path}preloader@2x.gif');
		background-size: 24px 24px;
	}

	.dark .preloader,
	.mfp-preloader{ 
		background-image: url('#{$img-path}preloader-dark@2x.gif');
		background-size: 24px 24px;
	}

}
/*-----------------------------------------------------------------------------------

Section Blocks

-----------------------------------------------------------------------------------*/

.section {
  line-height: $line_height-base;
  font-size: $font-size-base;
  text-transform: $font-tt-base;
  font-weight: $font-weight-base;
  letter-spacing: $font-spacing-base;
  /* margin: $section-margin; */
}

/* Search */

.section-search{
  font-size: 22px;
}

.icon-boxes-item {
  padding-top: 20px;
  padding-bottom: 10px;
}
/*-----------------------------------------------------------------------------------

Feature box

-----------------------------------------------------------------------------------*/


.feature-box {
    position: relative;
    margin: 20px 0;
    padding: 0;
    .fbox-image{
      display:block;
    }
		.fbox-icon{
			display: inline-block;
			background-color: $icon_box_bg;
      color: $icon_box_color;
			width:  60px;
			height: 60px;
      text-align: center;
			@include border-radius(50%);
			.icon{
	    	line-height: 60px;
	    	font-size: 1px;
        &:before{
          font-size: $icon_box_size;
        }
	    }
			.icon-nurse,
			.icon-comment,
			.icon-ear{
        &:before{
          font-size: 48px;
        }
			}
		}


    h3 {
			color: $brand-color;
			font-size: 16px;
      margin: 15px 0;
			font-weight: 700;
      a {
        color: inherit;
      }
    }

    p{
      font-size: 14px;
    }

}

.section-related{
  .feature-box{
    background: #fff;
    padding: 0;
  }
  .feature-content{
    padding: 10px 20px;
  }
}

.section-image-boxes{
  .feature-content{
    padding: 10px 0px;
    h3 { font-size: $font-size-h3 - 5; }
  }
}

@include xs() {
  .icon-boxes-item {
    border: 1px white solid;
  }
}

@include sm() {
  .icon-boxes-item {
    border: 1px white solid;
  }
}

@media (min-width: $screen-xs-min) {
  .section-icon-boxes,
  .section-image-boxes,
  .section-related{
    padding-left: 30px;
    padding-right: 30px;
  }



}

@media (min-width: $screen-sm-min) {
	.feature-box h3{
		min-height: 50px;
	}
	.col-sm-12 .feature-box h3{
		min-height: 30px;
	}
  .section-related,
	.section-image-boxes{
		.feature-box h3{
			min-height: 24px;
		}
	}

  .section-icon-boxes,
  .section-image-boxes,
  .section-related{
    padding-right: 0;
    padding-left: 0;
  }

  .section-related{
    .feature-content{
      overflow: hidden;
      padding-bottom: 0;
      height: 200px;
      position:relative;
      &:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 35px;
        @include gradient-vertical( rgba(255, 255, 255, 0) , #ffffff, 0, 50%);
      }
    }
  }

}

@media (min-width: $screen-md-min) {
	.col-sm-12 .feature-box h3,
  .feature-box h3{
			min-height: 50px;
  }
  .section-related,
	.section-image-boxes{
		.feature-box h3{ min-height: 24px;}
	}

  .section-related{
    .feature-content{
      height: 200px;
    }
    .col-md-3{
      .feature-content{
        height: 220px;
      }
    }
  }

  /*.section-icon-boxes,
  .section-image-boxes,
  .section-related{
    .col-md-6{
      .feature-box{
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  }*/

}


@media (min-width: $screen-lg-min) {

  /*.feature-box{
    padding: 0 20px;
  }*/

  .section-related{
    .feature-content{
      height: 180px;
    }
    .col-md-3{
      .feature-content{
        height: 205px;
      }
    }
  }
}

/*-----------------------------------------------------------------------------------

Pager

-----------------------------------------------------------------------------------*/


.pager,
.nav-links{
	text-align:left;
}

.nav-previous:before{
	content: '';
}

.nav-next:before{
	content: '';
}


.pager li > a, 
.pager li > span {
    display: inline-block;
    @include border-radius(4px);
    font-size: 14px;
}

.section-gallery {
  .equal {
    display: flex;
    display: -webkit-flex;
  }
  text-align:center;
  .gallery-item{
    cursor: zoom-in;
    margin: 15px auto;
    display: inline-block;
    img{
      margin: 0 auto;
    }
    border: 1px solid transparent;
    &:hover{
      border-color: $brand-primary;
    }
  }
}
/*-----------------------------------------------------------------------------------

Featured image title header

-----------------------------------------------------------------------------------*/

.featured-header-container {
	margin: -1px auto;
	position:relative;
	overflow:hidden;
	width:100%;
	-webkit-backface-visibility:hidden;
	z-index:1;
	border-bottom: 4px solid $brand-primary;
}

.featured-header {
	position:relative;
	width:100%;
	margin:0;
	padding:0;
}



/* Featured Header (VERTICAL CENTER) */

.featured-header{
  	background-position: 50% 50%;
  	background-repeat: no-repeat;
  	background-size: cover;
  	overflow: hidden;
  	margin: 0;
  	padding: 30px 0;
  	.container {
		position: relative;
		height: 100%;
		.slider-caption-wrapper{
			position:relative;
			width:100%;
			height:100%;
		}
		.slider-caption{
			position:relative;
			width:96%;
			left: 2%;
			right:2%;


			&.vcentered{
				position:relative;
				bottom: auto;
			}

			.slider-caption-inside{
				@include clearfix();
				padding: 0;
				display:inline-block;
			}

			&.slider-caption-right{
				left: 3%;
				right:0;
			}

			&.slider-caption-left{
				left: 0;
				right:3%;
			}

			&.slider-vertical-top{
				bottom:auto;
				top: 90px;
			}

			&.slider-vertical-bottom{
				bottom: 20px;
			}

			.slider-caption-dark{
				padding: 15px;
				background: rgba(0,0,0,0.5);
			}


			.slider-caption-light{
				padding: 15px;
				background: rgba(255,255,255,0.5);
				color: #333;
				text-shadow: none;
				.slide-title {
					color: #333;

				}
			}

		}
	}

	[data-caption-animate] {
		-webkit-backface-visibility: hidden;
	}

}

/* RESPONSIVE SLIDER SIZES */

.featured-header-container,
.featured-header-container .featured-header-outer,
.featured-header-container .featured-header{
	height: auto;
	min-height: 400px;
}



.featured-header-big,
.featured-header-big .featured-header-outer,
.featured-header-big .featured-header{
	min-height: 400px;
}

.featured-header-tiny,
.featured-header-tiny .featured-header-outer,
.featured-header-tiny .featured-header {
	height: $slider_tiny_height !important;
	max-height: $slider_tiny_height;
	min-height: $slider_tiny_height;
}


@media (min-width: $screen-xs-min) {

	.featured-header-container .featured-header{
		padding:40px 0;
	}

}


@media (min-width: $screen-sm-min) {


	.featured-header-container,
	.featured-header-container .featured-header-outer,
	.featured-header-container .featured-header{
		height:400px;
	}

	.featured-header-big,
	.featured-header-big .featured-header-outer,
	.featured-header-big .featured-header{
		height:500px;
	}

	.featured-header-tiny,
	.featured-header-tiny .featured-header-outer,
	.featured-header-tiny .featured-header{
		height: $slider_tiny_height;
	}


	.featured-header-container .featured-header{
		padding:0;
	}

	.featured-header-container .featured-header{
		.container {
				padding-left: 30px;
				padding-right: 30px;
				.slider-caption{
					width: 98%;
					left:1%;
					right:1%;
					&.vcentered{
					}

					&.slider-caption-right{
						left: 1%;
						right:0;
					}

					&.slider-caption-left{
						left: 0;
						right:1%;
					}

					&.slider-caption-right {
						text-align:right;

					}

					&.slider-caption-center {
						text-align:center;
					}
				}
		}
	}


}

@media (min-width: $screen-md-min) {

	.featured-header-container,
	.featured-header-container .featured-header-outer,
	.featured-header-container .featured-header{
		height:400px;
	}

	.featured-header-big,
	.featured-header-big .featured-header-outer,
	.featured-header-big .featured-header{
		height:500px;
	}

	.featured-header-tiny,
	.featured-header-tiny .featured-header-outer,
	.featured-header-tiny .featured-header{
		height: $slider_tiny_height;
	}

}

@media (min-width: $screen-lg-min) {

	.featured-header-container,
	.featured-header-container .featured-header-outer,
	.featured-header-container .featured-header{
		height:420px;
	}

	.featured-header-big,
	.featured-header-big .featured-header-outer,
	.featured-header-big .featured-header{
		height:600px;
	}

	.featured-header-tiny,
	.featured-header-tiny .featured-header-outer,
	.featured-header-tiny .featured-header{
		height: $slider_tiny_height;
	}

	.featured-header-container .featured-header .slider-caption{
		width:85%;
	}

}

@include xs () {
.featured-header {
	.slider-vertical-bottom {
		top: 150px;
	}
 }
}

/*-----------------------------------------------------------------------------------

Blog header

-----------------------------------------------------------------------------------*/


.default-header{

	  margin: -1px 0;
		padding: 30px 0;
	  background-color: $brand-title-bg;
	  .page-title,
	  .header-title{
	  	color: $brand-title-color;
	  	margin:0;
	  }

    .default-header-inner {

			.lnk-back{
				margin-bottom:20px;
				display:inline-block;
				.icon{
					font-size:16px;
				}
			}

			.author{
				text-transform:uppercase;
				font-size: 1.5em;
				margin-bottom:5px;
			}

			.entry-time{
				margin-bottom:5px;
				color: $grey-color;
			}

			.cat-links,
			.tags-links{
				font-size: 0.875em;
				margin-bottom: 3px;
			}

			.icon{
				display:inline-block;
				color: lighten($grey-color, 15%);
				font-size: 1em;
				margin-right:5px;
				position: relative;
				top: 2px;
			}

		}
}



@media (min-width: $screen-sm-min) {

	.header-stick-meta{
		.author{
			font-size: 1.7em;
		}
	}

}



@media (min-width: $screen-md-min) {

	.default-header{
    .default-header-inner {

			.link-back{
				margin-bottom: 30px;
			}

			.header-title {
				margin-bottom: 20px;
				margin-left: -1px;
			}

			.author{
				margin-bottom:5px;
			}

    }
  }

}



/*-----------------------------------------------------------------------------------*/
// Simple Page header
// -------------------------


.page-header {
  margin: -1px 0;
  padding: 30px 0;
  background-color: $brand-title-bg;
	border: 0;
  .page-title,
  .header-title{
  	color: $brand-title-color;
		margin: 0;
  }
}



/*-----------------------------------------------------------------------------------

Author

-----------------------------------------------------------------------------------*/

.header-author {
	margin: -1px 0;
	padding: 30px 0;
	background-color: $brand-title-bg;
  .post-author{
    .author-name{
       color: $brand-title-color;
    }
  }
}


/* Breadcrumbs */

.section-breadcrumbs{
	margin: 0;
	padding: 20px 0;
	background-color: $brand-title-bg;
}

.page-header + .section-breadcrumbs{
	margin-top: -20px;
	padding: 0 0 20px;
}


.slider-container + .page-header,
.slider-container + .default-header,
.slider-container + .header-author
.featured-header-container + .page-header,
.featured-header-container + .default-header,
.featured-header-container + .header-author{
	margin-top: -2px;
	margin-bottom: -2px;
	position:relative;
	z-index: 1;
	+ .section-breadcrumbs{
		position: relative;
		z-index: 1;
	}
}

.breadcrumb{
	padding: 0;
	margin: 0;
	list-style: none;
  background: transparent;
	font-size: .875em;
	li{
		padding: 0;
		margin: 0;
		&.active a{
			color: $gray-light;
		}
		+ li:before{
			content: "/";
			padding: 0 7px;
			color: $gray-light;
			opacity: .7;
		}
	}
}

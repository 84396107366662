
.cats{
  .cat{
    margin: 10px 0;
    background: #bbb;
    color: #fff;
    display: inline-block;
    padding: 2px 5px;
    line-height: 1.4em;
    font-weight: 500;
    font-family: $heading-font;
    letter-spacing: .3pt;
    @include border-radius(2px);
  }
}

.artwork{
  text-align: center;
  margin: 20px 0 40px;
  .artwork-img{
    display: block;
    position: relative;
    img{
      display: block;
      position: relative;
      width: auto;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
  .artwork-title{
    font-size: 18px;
    margin: 20px 10px 10px;
    a{
      color: $dark-color;
    }
    
  }
  .artwork-author{
    font-size: 14px;
    margin: 0 10px 10px;
    color: $grey-color;
  }
  
  .artwork-edition,
  .artwork-isbn{
    margin: 0 auto 5px;
    font-size: 14px;
    font-weight: 400;
  }
  
  .artwork-web{
    font-weight: 500;
  }
}

.artwork-page {
  
  .artwork{
    border-bottom: 1px solid #eee;
    padding-bottom: 40px;
    margin-bottom: 40px;
    .artwork-title{
      font-weight: 500;
      font-size: 25px;
      margin: 30px 0 10px;
    }
    .artwork-author{
      font-size: 18px;
      padding:0;
      margin: 0 0 5px;
      &:before{
        display:none;
      }
    }
    .artwork-year{
      font-weight: 700;
      color: #aaa;
    }
  }
  
}


.media-artworks{
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px dotted #d2d2d2;
  .media-left{
    padding-right: 20px;
    a{
      display:inline-block;
      width: 100px;
      img{
        width: 100%;
        height: auto;
      }
    }
  }
  
  .media-body{
    .artwork-title{
      font-size: 22px;
      margin: 0 0 5px;
    }
    .artwork-author{
      font-size: 18px;
      padding:0;
      margin: 0 0 10px;
      color: $grey-color;
    }
    .artwork-edition,
    .artwork-isbn{
      margin: 0 auto 5px;
      font-size: 14px;
      font-weight: 400;
    }
    
    .artwork-web{
      font-weight: 500;
    }
    
    .artwork-resum{
      margin-top: 20px;
    }
    
  }
}


@media (min-width: $screen-sm-min){

  .media-artworks{
    .media-left{
      padding-right: 30px;
      a{
        width: 200px;
      }
    }
  }
}
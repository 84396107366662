/*-----------------------------------------------------------------------------------

Helpers

-----------------------------------------------------------------------------------*/

.pagination {
	> .active {
		> a,
		> span,
		> a:hover,
		> span:hover,
		> a:focus,
		> span:focus {
			color: #FFF !important;
			background-color: $primary-color !important;
			border-color: $primary-color !important;
		}
	}
}

.img-circle {
	@include border-radius(50%!important);
}

.uppercase { text-transform: uppercase !important; }

.lowercase { text-transform: lowercase !important; }

.capitalize { text-transform: capitalize !important; }

.nott { text-transform: none !important; }

.tright { text-align: right !important; }

.tleft { text-align: left !important; }

.fright { float: right !important; }

.fleft { float: left !important; }

.fnone { float: none !important; }

.ohidden {
	position: relative;
	overflow: hidden !important;
}

.line,
.double-line {
	clear: both;
	position: relative;
	width: 100%;
	margin: ($margin-base + 10) 0;
	border-top: 1px solid #EEE;

	&.line-sm {
		margin: $margin-small 0;
	}
}

span.middot {
	display: inline-block;
	margin: 0 5px;
}

.double-line { border-top: 3px double #E5E5E5; }

.emptydiv {
	display: block !important;
	position: relative !important;
}

.allmargin { margin: $margin-base !important; }

.leftmargin { margin-left: $margin-base !important; }

.rightmargin { margin-right: $margin-base !important; }

.topmargin { margin-top: $margin-base !important; }

.bottommargin { margin-bottom: $margin-base !important; }

.clear-bottommargin { margin-bottom: -$margin-base !important; }

.allmargin-sm { margin: $margin-small !important; }

.leftmargin-sm { margin-left: $margin-small !important; }

.rightmargin-sm { margin-right: $margin-small !important; }

.topmargin-sm { margin-top: $margin-small !important; }

.bottommargin-sm { margin-bottom: $margin-small !important; }

.clear-bottommargin-sm { margin-bottom: -$margin-small !important; }

.allmargin-lg { margin: $margin-big !important; }

.leftmargin-lg { margin-left: $margin-big !important; }

.rightmargin-lg { margin-right: $margin-big !important; }

.topmargin-lg { margin-top: $margin-big !important; }

.bottommargin-lg { margin-bottom: $margin-big !important; }

.clear-bottommargin-lg { margin-bottom: -$margin-big !important; }

.nomargin {margin: 0 !important; }

.noleftmargin { margin-left: 0 !important; }

.norightmargin { margin-right: 0 !important; }

.notopmargin { margin-top: 0 !important; }

.nobottommargin { margin-bottom: 0 !important; }

.header-stick { margin-top: -$margin-base !important; }

.content-wrap .header-stick { margin-top: -$margin-big !important; }

.footer-stick { margin-bottom: -$margin-base !important; }

.content-wrap .footer-stick { margin-bottom: -$margin-big !important; }

.noborder { border: none !important; }

.noleftborder { border-left: none !important; }

.norightborder { border-right: none !important; }

.notopborder { border-top: none !important; }

.nobottomborder { border-bottom: none !important; }

.noradius { @include border-radius(0!important); }

.col-padding { padding: 60px; }

.nopadding { padding: 0 !important; }

.bigpadding { padding: $margin-big 0 !important; }

.extrapadding { padding: $margin-big 0 !important; }
@media (min-width: $screen-md-min) {
	.extrapadding { padding: $margin-big * 2 0 !important; }
}

.noleftpadding { padding-left: 0 !important; }

.norightpadding { padding-right: 0 !important; }

.notoppadding { padding-top: 0 !important; }

.nobottompadding { padding-bottom: 0 !important; }

.noabsolute { position: relative !important; }

.noshadow { box-shadow: none !important; }

.hidden { display: none !important; }

.nothidden { display: block !important; }

.inline-block {
	float: none !important;
	display: inline-block !important;
}

.center { text-align: center !important; }

.divcenter {
	position: relative !important;
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.nobg { background: none !important; }

.nobgcolor { background-color: transparent !important; }

.bgicon {
	display: block;
	position: absolute;
	bottom: -60px;
	right: -50px;
	font-size: 210px;
	color: rgba(0,0,0,0.1);
}

.input-block-level {
	display: block;
	width: 100% !important;
	min-height: 30px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.not-animated{
	opacity: 0; 
}

/* Vertical center */
.vcenter:before { content: ''; display: inline-block; height: 100%; vertical-align: middle;}
.vcenter > .vcentered { display:inline-block; vertical-align: middle;}


.preloader {
    display: block;
    width: 100%;
    height: 100%;
    background: url("#{$img-path}preloader.gif") center center no-repeat #FFF;
}

.dark .preloader {
    background: url("#{$img-path}preloader-dark.gif") center center no-repeat #FFF;
}


/** seven columns strategy when needed */
@media (min-width: 768px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1  {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
  }
}

@media (min-width: 1200px) {
  .seven-cols .col-md-1,
  .seven-cols .col-sm-1,
  .seven-cols .col-lg-1 {
    width: 14.285714285714285714285714285714%;
  }
}

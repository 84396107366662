/*-----------------------------------------------------------------------------------

Posts

-----------------------------------------------------------------------------------*/


.entry-img,
.entry-img > a,
.entry-img img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}

.entry-title {
	line-height:1.4em;
	font-size: 1.2em;
}

.entry-time{
	display:block;
	line-height:1.3em;
	color: #999;
	margin-bottom:10px;
}


.entry-content {
	position: relative;
}

.post-author{
	min-height: 50px;
	.author-thumb {
	    display: block;
	    width: 70px;
	    height: 70px;
	    position: absolute;
	    left: $grid-gutter-width / 2;
	    top: 0;
	    background-color: #fff;
	    background-image: url("#{$img-path}avatar.png");
	    -webkit-background-size: cover;
	    background-size: cover;
	    background-repeat: no-repeat;
			overflow:hidden;
			@include border-radius(50%);
	    img{
	    	height:70px;
				width: auto;
	    }
	}
	.author-details{
		margin-left: 100px;
		text-align: left;
		.author-name{
			display:block;
			font-size: 1.7em;
			text-transform: uppercase;
			margin-bottom:5px;
			line-height:1.2em;
		}
		.author-resume{
			margin-top:0;
			margin-bottom:0;
		}
	}
}

.no-author-details{
	.post-author{
		.author-details{
			margin-top: 20px;
		}
	}
}


/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/

.posts-wrapper{
	@include clearfix();
}

.posts{
	@include clearfix();
	.hentry{
		position:relative;
		/* width:100%; */
		float:left;
		margin-bottom: 30px;

		> a{
			position:relative;
			display:block;
		}

		.entry-img{
			position:relative;
			overflow:hidden;
			background: #d5d5d5;
		}

		.entry-header{
			margin-top: 20px;
		}

		&:nth-child(odd){
			clear:left;
		}

	}

}


@media (min-width: $screen-xs-min) {
	.post-wrapper{
		margin-left:-16px;
		margin-right:-16px;
	}
	.posts{
		margin-left:-10px;
		margin-right:-10px;
		.hentry{
			width:50%;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}


@media (min-width: $screen-md-min) {

	.entry-title {
		font-size:1.3em;
	}

	.posts{
		margin-left:-16px;
		margin-right:-16px;
		.hentry{
			width:33%;
			padding-left: 16px;
			padding-right: 16px;
			&:nth-child(odd){
				clear:none;
			}
			&:nth-child(3n+1){
				clear:left;
			}
		}
	}

	.main{
		.posts{
			margin-left:-16px;
			margin-right:-16px;
			.hentry{
				width:100%;
				padding-left: 16px;
				padding-right: 16px;
				&:nth-child(3n+1){
					clear:none;
				}
				&:nth-child(odd){
					clear:left;
				}

			}
		}
	}

}


/* Single post */


@media (min-width: $screen-md-min) {
	.row-single{
		margin-right: - $grid-gutter-width;
	}
	.col-single{
		padding:0;
	}
}



/* Tiny Entry */


.tiny-entry{
	min-height:150px;
	.entry-title{
		font-family: $secondary-font;
		text-transform: none;
		font-weight:normal;
		font-size: 1em;
		margin-bottom:10px;
	}
	.entry-time{
		font-size:.875em;
    margin-top: -5px;
    margin-bottom: 5px;
	}
	.entry-content{
		font-size: .875em;
		margin-bottom:5px;
		.date{
			font-size:.75em;
		}
	}
}


/* Post Elements
-----------------------------------------------------------------*/

img,
div {
	&.alignleft {
		float: left;
		margin: 5px 20px 13px 0;
		max-width: 100%;
	}
}

div {
	&.alignleft,
	&.alignnone,
	&.aligncenter,
	&.alignright {
		> img {
			display: block;
			float: none;
		}
	}
}

img.alignnone,
img.aligncenter,
div.alignnone,
div.aligncenter {
	display: block;
	margin: 10px 0;
	float: none;
}

img.aligncenter,
div.aligncenter,
div.aligncenter img {
	margin-left: auto;
	margin-right: auto;
	clear: both;
}

img,
div {
	&.alignright {
		float: right;
		margin: 5px 0 13px 20px;
	}
}

.wp-caption {
  text-align: left;
  padding: 40px 0;
  line-height: 1.5;
  background-color: inherit;
  border: 0;
  border-radius: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  max-width:100%;
	img,
	img a {
		display: block;
		margin: 0;
	}
	.wp-caption-text,
	p.wp-caption-text{
		text-align:center;
	}
}

.wp-caption-text,
p.wp-caption-text {
	display: block;
	margin: 10px 0 0 0;
	padding: 5px 10px;
	color: $grey-color;
	font-size:16px;
}

.wp-smiley {
	max-height: 13px;
	margin: 0 !important;
}

/* Mixins
-----------------------------------------------------------------*/

@mixin text-overflow(){
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
}

@mixin border-radius($radius: 3px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition($transition) {
	-webkit-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}

@mixin transition-transform($transition) {
	-webkit-transition: -webkit-transform $transition;
	-moz-transition: -moz-transform $transition;
	-o-transition: -o-transform $transition;
	transition: transform $transition;
}

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:focus,
  &.focus {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 10%);
  }
  &:hover {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 10%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 5%);
        border-color: darken($border, 5%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 10%);
          border-color: darken($border, 10%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}


/**
  @victor 
  media mixins for media queries
  */

@mixin xs() {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin sm() {
   @media (min-width:481px) and (max-width:767px) {
       @content;
   }
}

@mixin md() {
 @media (min-width: 768px) and (max-width: 991px) {
       @content;
 } 
}

@mixin lg() {
  @media (min-width: 992px) and (max-width: 1199px) {
       @content;
   }
}

@mixin xlg() {
  @media (min-width: 1200px) {
       @content;
   }
}

/*
@mixin xs() {
    @media (max-width: 767px) {
        @content;
    }
}
@mixin sm() {
    @media (min-width: 768px) and (max-width: 991px) {
        @content;
    }
}
@mixin md() {
    @media (min-width: 992px) and (max-width: 1199px) {
        @content;
    }
}
@mixin lg() {
    @media (min-width: 1200px) {
        @content;
    }
}
*/



// Fonts
// ------------------------


@font-face {
    font-family: 'teseoicons';
    src:    url('#{$font-path}/teseoicons.eot?u2rumu');
    src:    url('#{$font-path}/teseoicons.eot?u2rumu#iefix') format('embedded-opentype'),
        url('#{$font-path}/teseoicons.ttf?u2rumu') format('truetype'),
        url('#{$font-path}/teseoicons.woff?u2rumu') format('woff'),
        url('#{$font-path}/teseoicons.svg?u2rumu#teseoicons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $icons-font-family !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-nurse {
    &:before {
        content: $icon-nurse;
    }
}
.icon-ear {
    &:before {
        content: $icon-ear;
    }
}
.icon-comment {
    &:before {
        content: $icon-comment;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
.icon-phone-outline {
    &:before {
        content: $icon-phone-outline;
    }
}
.icon-home {
    &:before {
        content: $icon-home;
    }
}
.icon-chatbubble {
    &:before {
        content: $icon-chatbubble;
    }
}
.icon-chatbubbles {
    &:before {
        content: $icon-chatbubbles;
    }
}
.icon-chatbox {
    &:before {
        content: $icon-chatbox;
    }
}
.icon-chatboxes {
    &:before {
        content: $icon-chatboxes;
    }
}
.icon-alert {
    &:before {
        content: $icon-alert;
    }
}
.icon-help {
    &:before {
        content: $icon-help;
    }
}
.icon-info {
    &:before {
        content: $icon-info;
    }
}
.icon-warning {
    &:before {
        content: $icon-warning;
    }
}
.icon-at {
    &:before {
        content: $icon-at;
    }
}
.icon-pound {
    &:before {
        content: $icon-pound;
    }
}
.icon-asterisk {
    &:before {
        content: $icon-asterisk;
    }
}
.icon-attach {
    &:before {
        content: $icon-attach;
    }
}
.icon-walk {
    &:before {
        content: $icon-walk;
    }
}
.icon-bicycle {
    &:before {
        content: $icon-bicycle;
    }
}
.icon-car {
    &:before {
        content: $icon-car;
    }
}
.icon-bus {
    &:before {
        content: $icon-bus;
    }
}
.icon-subway {
    &:before {
        content: $icon-subway;
    }
}
.icon-train {
    &:before {
        content: $icon-train;
    }
}
.icon-boat {
    &:before {
        content: $icon-boat;
    }
}
.icon-plane {
    &:before {
        content: $icon-plane;
    }
}
.icon-cart {
    &:before {
        content: $icon-cart;
    }
}
.icon-bar {
    &:before {
        content: $icon-bar;
    }
}
.icon-restaurant {
    &:before {
        content: $icon-restaurant;
    }
}
.icon-lab {
    &:before {
        content: $icon-lab;
    }
}
.icon-bag {
    &:before {
        content: $icon-bag;
    }
}
.icon-card {
    &:before {
        content: $icon-card;
    }
}
.icon-android-home {
    &:before {
        content: $icon-android-home;
    }
}
.icon-tshirt {
    &:before {
        content: $icon-tshirt;
    }
}
.icon-trophy {
    &:before {
        content: $icon-trophy;
    }
}
.icon-university {
    &:before {
        content: $icon-university;
    }
}
.icon-umbrella {
    &:before {
        content: $icon-umbrella;
    }
}
.icon-cloud {
    &:before {
        content: $icon-cloud;
    }
}
.icon-eye {
    &:before {
        content: $icon-eye;
    }
}
.icon-flag {
    &:before {
        content: $icon-flag;
    }
}
.icon-flame {
    &:before {
        content: $icon-flame;
    }
}
.icon-leaf {
    &:before {
        content: $icon-leaf;
    }
}
.icon-waterdrop {
    &:before {
        content: $icon-waterdrop;
    }
}
.icon-lightbulb {
    &:before {
        content: $icon-lightbulb;
    }
}
.icon-notifications {
    &:before {
        content: $icon-notifications;
    }
}
.icon-location {
    &:before {
        content: $icon-location;
    }
}
.icon-map {
    &:before {
        content: $icon-map;
    }
}
.icon-print {
    &:before {
        content: $icon-print;
    }
}
.icon-mail {
    &:before {
        content: $icon-mail;
    }
}
.icon-person {
    &:before {
        content: $icon-person;
    }
}
.icon-people {
    &:before {
        content: $icon-people;
    }
}
.icon-time {
    &:before {
        content: $icon-time;
    }
}
.icon-clock {
    &:before {
        content: $icon-clock;
    }
}
.icon-refresh {
    &:before {
        content: $icon-refresh;
    }
}
.icon-sync {
    &:before {
        content: $icon-sync;
    }
}
.icon-loop-strong {
    &:before {
        content: $icon-loop-strong;
    }
}
.icon-loop {
    &:before {
        content: $icon-loop;
    }
}
.icon-reload {
    &:before {
        content: $icon-reload;
    }
}
.icon-more-thin {
    &:before {
        content: $icon-more-thin;
    }
}
.icon-more {
    &:before {
        content: $icon-more;
    }
}
.icon-navicon {
    &:before {
        content: $icon-navicon;
    }
}
.icon-navicon-round {
    &:before {
        content: $icon-navicon-round;
    }
}
.icon-arrow-down-thin {
    &:before {
        content: $icon-arrow-down-thin;
    }
}
.icon-forward {
    &:before {
        content: $icon-forward;
    }
}
.icon-reply {
    &:before {
        content: $icon-reply;
    }
}
.icon-reply-all {
    &:before {
        content: $icon-reply-all;
    }
}
.icon-arrow-left-thin {
    &:before {
        content: $icon-arrow-left-thin;
    }
}
.icon-arrow-right-thin {
    &:before {
        content: $icon-arrow-right-thin;
    }
}
.icon-arrow-up-thin {
    &:before {
        content: $icon-arrow-up-thin;
    }
}
.icon-chevron-down {
    &:before {
        content: $icon-chevron-down;
    }
}
.icon-chevron-left {
    &:before {
        content: $icon-chevron-left;
    }
}
.icon-chevron-right {
    &:before {
        content: $icon-chevron-right;
    }
}
.icon-chevron-up {
    &:before {
        content: $icon-chevron-up;
    }
}
.icon-checkmark {
    &:before {
        content: $icon-checkmark;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-minus {
    &:before {
        content: $icon-minus;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-edit {
    &:before {
        content: $icon-edit;
    }
}
.icon-close-thin {
    &:before {
        content: $icon-close-thin;
    }
}
.icon-plus-thin {
    &:before {
        content: $icon-plus-thin;
    }
}
.icon-minus-thin {
    &:before {
        content: $icon-minus-thin;
    }
}
.icon-thumbsdown {
    &:before {
        content: $icon-thumbsdown;
    }
}
.icon-thumbsup {
    &:before {
        content: $icon-thumbsup;
    }
}
.icon-delete {
    &:before {
        content: $icon-delete;
    }
}
.icon-unlock {
    &:before {
        content: $icon-unlock;
    }
}
.icon-lock {
    &:before {
        content: $icon-lock;
    }
}
.icon-pin {
    &:before {
        content: $icon-pin;
    }
}
.icon-paper-airplane {
    &:before {
        content: $icon-paper-airplane;
    }
}
.icon-pricetag {
    &:before {
        content: $icon-pricetag;
    }
}
.icon-pricetags {
    &:before {
        content: $icon-pricetags;
    }
}
.icon-stats-bars {
    &:before {
        content: $icon-stats-bars;
    }
}
.icon-pie-graph {
    &:before {
        content: $icon-pie-graph;
    }
}
.icon-wifi {
    &:before {
        content: $icon-wifi;
    }
}
.icon-play {
    &:before {
        content: $icon-play;
    }
}
.icon-pause {
    &:before {
        content: $icon-pause;
    }
}
.icon-stop {
    &:before {
        content: $icon-stop;
    }
}
.icon-record {
    &:before {
        content: $icon-record;
    }
}
.icon-star {
    &:before {
        content: $icon-star;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
    }
}
.icon-gear {
    &:before {
        content: $icon-gear;
    }
}
.icon-music-note {
    &:before {
        content: $icon-music-note;
    }
}
.icon-color-palette {
    &:before {
        content: $icon-color-palette;
    }
}
.icon-image {
    &:before {
        content: $icon-image;
    }
}
.icon-images {
    &:before {
        content: $icon-images;
    }
}
.icon-film {
    &:before {
        content: $icon-film;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-calculator {
    &:before {
        content: $icon-calculator;
    }
}
.icon-bookmark-strong {
    &:before {
        content: $icon-bookmark-strong;
    }
}
.icon-bookmark {
    &:before {
        content: $icon-bookmark;
    }
}
.icon-camera {
    &:before {
        content: $icon-camera;
    }
}
.icon-list {
    &:before {
        content: $icon-list;
    }
}
.icon-clipboard {
    &:before {
        content: $icon-clipboard;
    }
}
.icon-document {
    &:before {
        content: $icon-document;
    }
}
.icon-folder {
    &:before {
        content: $icon-folder;
    }
}
.icon-monitor {
    &:before {
        content: $icon-monitor;
    }
}
.icon-laptop {
    &:before {
        content: $icon-laptop;
    }
}
.icon-ipad {
    &:before {
        content: $icon-ipad;
    }
}
.icon-iphone {
    &:before {
        content: $icon-iphone;
    }
}
.icon-earth {
    &:before {
        content: $icon-earth;
    }
}
.icon-briefcase {
    &:before {
        content: $icon-briefcase;
    }
}
.icon-medkit {
    &:before {
        content: $icon-medkit;
    }
}
.icon-share {
    &:before {
        content: $icon-share;
    }
}
.icon-load-a {
    &:before {
        content: $icon-load-a;
    }
}
.icon-load-b {
    &:before {
        content: $icon-load-b;
    }
}
.icon-load-c {
    &:before {
        content: $icon-load-c;
    }
}
.icon-load-d {
    &:before {
        content: $icon-load-d;
    }
}
.icon-social-facebook {
    &:before {
        content: $icon-social-facebook;
    }
}
.icon-social-google {
    &:before {
        content: $icon-social-google;
    }
}
.icon-social-googleplus {
    &:before {
        content: $icon-social-googleplus;
    }
}
.icon-social-instagram {
    &:before {
        content: $icon-social-instagram;
    }
}
.icon-social-linkedin {
    &:before {
        content: $icon-social-linkedin;
    }
}
.icon-social-linkedin-fill {
    &:before {
        content: $icon-social-linkedin-fill;
    }
}
.icon-social-pinterest {
    &:before {
        content: $icon-social-pinterest;
    }
}
.icon-social-rss {
    &:before {
        content: $icon-social-rss;
    }
}
.icon-social-skype {
    &:before {
        content: $icon-social-skype;
    }
}
.icon-social-twitter {
    &:before {
        content: $icon-social-twitter;
    }
}
.icon-social-vimeo {
    &:before {
        content: $icon-social-vimeo;
    }
}
.icon-social-youtube {
    &:before {
        content: $icon-social-youtube;
    }
}


// Buttons
// --------------------------------------------------

.btn {
  position:relative;
  font-weight: 300;
  background-image: none;
  border: 1px solid transparent;
  letter-spacing: .2pt;
  white-space:normal;
  font-family: $secondary-font;
  i.icon{
    color: inherit;
  }
}

.btn-icon-left{
  .icon{
    position:relative;
    padding-right:5px;
  }
}

.btn-icon-right{
  .icon{
    position:relative;
    padding-left:5px;
  }
}

.btn{
  .icon{
    font-size: 21px;
    line-height: 16px;
    top: 3px;
  }
}

.btn-sm{
  .icon{
    font-size: 16px;
    line-height: 14px;
    top: 2px;
  }
}

.btn-lg{
  .icon{
    font-size: 24px;
    line-height: 20px;
    top: 3px;
  }
}

.nav-next{
  float:right;
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.btn-md {
  @include button-size($padding-md-vertical, $padding-md-horizontal, $font-size-md, $line-height-base, $btn-border-radius-base);
}

/*-----------------------------------------------------------------------------------

Typography

-----------------------------------------------------------------------------------*/


dl, dt, dd, ol, ul, li {
	margin: 0;
	padding: 0;
}

::selection {
	background: $brand-color;
	color: #FFF;
	text-shadow: none;
}

::-moz-selection {
	background: $brand-color; /* Firefox */
	color: #FFF;
	text-shadow: none;
}

::-webkit-selection {
	background: $brand-color; /* Safari */
	color: #FFF;
	text-shadow: none;
}

:active,
:focus { outline: none !important; }

body {
	line-height: $line-height-base;
	color: $body-color;
	font-size: $font-size-base;
	font-family: $font-family-base;
	font-weight: 300;
}

a {
	text-decoration: none !important;
	img {
		border: none;
	}
}

i, em{
	font-style: italic
}

b,strong{
	font-weight: bold;
}

i.icon{
	color: inherit;
}

.em{
	color: $secondary-color;
}

/* ----------------------------------------------------------------
	Layout Styles
-----------------------------------------------------------------*/

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	color: $heading-color;
	font-weight: 500;
	line-height: 1.4;
	margin: 0 0 35px 0;
	font-family: $heading-font;
	i, em{
		font-family: $heading-font;
		color: $secondary-color;
		font-style: normal;
	}
	strong, b{
		font-weight: bold;
	}
}

h1, .h1, h2, .h2{
	font-weight: 300;
}

h1, .h1 { font-size: $font-size-h1; }

h2, .h2 { font-size: $font-size-h2; }

h3, .h3 { font-size: $font-size-h3; color: $h3-color; font-weight: bold; }

h4, .h4 { font-size: $font-size-h4; }

h5, .h5 { font-size: $font-size-h5; color: $h5-color; font-weight: bold; }

h6, .h6 { font-size: $font-size-h6; }

h2, .h2{
	text-transform: uppercase;
}

h4, .h4 { font-weight: normal; }

h5, .h5
h6, .h6{ 
	font-weight: normal;
	margin-bottom: 20px;  
}

.alert{
	h4{margin-bottom:0;}
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form { margin-bottom: 30px; }

small { }

ul{
	list-style-type:none;
	li{
		position:relative;
		padding-left:40px;
		margin-bottom: 20px;
		&:before{
			color: $brand-color;
			content: $icon-chevron-right;
			position: absolute;
			left: 11px;
			top: 5px;
			font-size:0.875em;
			display:inline-block;
			font-family: $icons-font-family;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}
}

ul.nav,
ul.list-unstyled,
ul.list-inline,
ul.clients-ticker{
	li{
		padding:0;
		margin:0;
		&:before{
			content: '';
			display:none;
		}
	}

}

ul.list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

ol{
	padding-left: 30px;
	li{
		margin-bottom: 20px;
		padding-left:10px;
	}
}


blockquote {
  border: 0;
	font-size: 1.1em;
  p:before{
    color: $brand-color;
		content: "\201C";
		font-size: 6em;
		line-height: 1.5em;
		height: 16px;
		position: relative;
		top: 0.48em;
		margin-right: 0.05em;
		display: inline-block;
		margin-top: -1em;
  }
  p:after{
    color: $brand-color;
		content: "\201D";
		font-size: 6em;
		line-height: 1.5em;
		height: 16px;
		position: relative;
		top: 0.6em;
		margin-left: 0.05em;
		display: inline-block;
		margin-top: -1em;
  }
}

q::before {
    content: "\201C";
    margin-right: 7px;
}

q::after {
    content: "\201D";
    margin-left: 7px;
}

q::before,
q::after{
	font-size: 36px;
	line-height: 0;
    height: 20px;
    position: relative;
    top: 12px;
}


.page-title{
	font-size: 32px;
}

.section-title{
	font-size: 28px;
}

.page-title,
.section-title{
	font-weight: 300;
	text-transform: uppercase;
}


@media (min-width: $screen-md-min) {

	.page-title{
		font-size: 40px;
	}
	
	.section-title{
		font-size: 36px;
	}

}


@media (min-width: $screen-lg-min) {

	.page-title{
		font-size: 42px;
	}

}
/* ----------------------------------------------------------------

Page Transitions

-----------------------------------------------------------------*/

body:not(.device-touch) a{
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

body:not(.device-touch) .btn{
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

body.with-transitions #wrapper,
.animsition-overlay {
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


.animsition-overlay {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: inherit;
}


.animsition-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
  background-color: inherit;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animsition-spinner > div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: 13px;
  width: 18px;
  height: 18px;
  background-color: #DDD;
  @include border-radius(100%);
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animsition-spinner .spin1 {
  margin-left: -39px;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.animsition-spinner .spin2 {
  margin-left: -13px;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}

.animsition-spinner .spin3 {
  margin-left: 13px;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}

.animsition-spinner .spin4 {
  margin-left: 39px;
  -webkit-animation-delay: 0.48s;
  animation-delay: 0.48s;
}


@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
  transform: scale(0.0);
  -webkit-transform: scale(0.0);
  } 40% {
  transform: scale(1.0);
  -webkit-transform: scale(1.0);
  }
}


/*---------------------------------------------------
Animations
----------------------------------------------------*/


@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.fade-in {
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
}


@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.fade-out {
  -webkit-animation-name: fade-out;
          animation-name: fade-out;
}



@-webkit-keyframes fade-in-up-sm {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-up-sm {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-up-sm {
  -webkit-animation-name: fade-in-up-sm;
          animation-name: fade-in-up-sm;
}


@-webkit-keyframes fade-out-up-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
}


@keyframes fade-out-up-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
}


.fade-out-up-sm {
  -webkit-animation-name: fade-out-up-sm;
          animation-name: fade-out-up-sm;
}

@-webkit-keyframes fade-in-down-sm {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-down-sm {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-down-sm {
  -webkit-animation-name: fade-in-down-sm;
          animation-name: fade-in-down-sm;
}


@-webkit-keyframes fade-out-down-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
}


@keyframes fade-out-down-sm {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
}


.fade-out-down-sm {
  -webkit-animation-name: fade-out-down-sm;
          animation-name: fade-out-down-sm;
}

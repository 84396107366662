/*-----------------------------------------------------------------------------------

Cookie Consent

-----------------------------------------------------------------------------------*/

/* Cookies disclaimer */
     
.alert-cookies{
	margin:0;
    position:fixed;
    z-index:1000;
    bottom:0;
    left:0;
    width:100%;
    background: $brand-color-light;
    text-align:center;
    font-size: 16px;

    p{
    	margin:0;
    }

    .alert-cookies-content{
    	padding:5px 30px;
    }


    .close{
	    position: absolute;
	    top: 50%;
	    margin-top:-16px;
	    right: 20px;
	    color: $brand-color;
    	text-shadow: none;
    	font-size:32px;
    	opacity: .8;
    	z-index:1;
    	&:hover,
    	&:focus{
    		opacity: 1;
    	}
	}

	&.cookies-to-top{
        position:relative;
        bottom:auto;
        top:0;
        left:0;
        width:100%;
    }
}
